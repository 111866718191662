import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Progress, List } from 'antd';
import { useModal } from 'hooks/useModal';
import { Tenant } from 'interfaces/tenants';
import { useNavigate } from 'react-router-dom';
import { setCurrentTenant } from 'slices/tenantsSlice';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const TenantsList = ({
    onClick,
}: {
    onClick?: (tenant: Tenant) => void;
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { tenants, currentTenant, loading } = useAppSelector((state) => state.tenants);
    const { openModal } = useModal();

    const handleTenantSwitch = (tenant: Tenant) => {
        dispatch(setCurrentTenant(tenant));
        navigate('/documents');
        onClick && onClick(tenant);
    };

    const handleAddWorkspace = () => {
        openModal('tenantCreateModal');
    };

    // When there are no tenants, show only the button, loading indicator and an info alert.
    if (!tenants || tenants.length === 0) {
        return (
            <>
                <Button
                    type="text"
                    icon={<PlusOutlined />}
                    block
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.stopPropagation();
                        handleAddWorkspace();
                    }}
                    style={{ marginBottom: 8 }}
                >
                    Add Workspace
                </Button>
                {loading && (
                    <Progress
                        type="line"
                        percent={100}
                        status="active"
                        showInfo={false}
                        style={{ position: 'absolute', left: 0, top: 0, width: '100%' }}
                    />
                )}
                <Alert
                    message="No Workspaces Available"
                    type="info"
                    style={{ margin: 8 }}
                />
            </>
        );
    }

    // When there are tenants, show the add button, loading indicator and then the list of tenants.
    return (
        <>
            <Button
                type="text"
                icon={<PlusOutlined />}
                block
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.stopPropagation();
                    handleAddWorkspace();
                }}
                style={{ marginBottom: 8 }}
            >
                Add Workspace
            </Button>
            {loading && (
                <Progress
                    type="line"
                    percent={100}
                    status="active"
                    showInfo={false}
                    style={{ position: 'absolute', left: 0, top: 0, width: '100%' }}
                />
            )}
            <List
                dataSource={tenants}
                renderItem={(item: Tenant) => (
                    <List.Item
                        key={item.name}
                        onClick={() => handleTenantSwitch(item)}
                        style={{
                            padding: '12px 24px',
                            backgroundColor: (currentTenant && (item.id === currentTenant.id)) ? "#e6f7ff" : "transparent",
                            cursor: 'pointer'
                        }}
                    >
                        <List.Item.Meta
                            title={item.title}
                            description={item.domain}
                        />
                    </List.Item>
                )}
            />
        </>
    );
};

export default TenantsList;