import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { DateTime } from 'luxon';
import { countBusinessDays, FORMAT_DATE, FORMAT_DATE_FOR_SAVE, getEndDateGivenBusinessDays } from 'smx-components';
import { FormInstance } from 'antd/es/form';
import { NewItemType } from '../types';
import dayjs from 'dayjs';

const useHandleDateRange2 = (form: FormInstance<NewItemType>) => {
    const [durationCount, setDurationCount] = useState<number>(0);
    const [dateEnd, setDateEnd] = useState<string>();
    const [dateStart, setDateStart] = useState<string>();

    const values = Form.useWatch([], form);
    const start = DateTime.fromISO(values?.startDate?.toISOString());

    useEffect(() => {
        if (!values?.duration) {
            return;
        }

        const endDate = values.businessDays
            ? getEndDateGivenBusinessDays(start, values.duration)
            : start.plus({ days: values?.duration - 1 });

        setDateEnd(endDate.toFormat(FORMAT_DATE));
    }, [values?.duration, values?.businessDays]);

    useEffect(() => {
        if (!values?.startDate || !values?.endDate) {
            return;
        }

        const end = DateTime.fromISO(values.endDate.format(FORMAT_DATE_FOR_SAVE));
        const duration = values.businessDays
            ? countBusinessDays(start, end)
            : Math.ceil(end.diff(start, 'days').days) + 1;

        if (values.endDate.format(FORMAT_DATE_FOR_SAVE) !== dateEnd || start.toFormat(FORMAT_DATE) !== dateStart) {
            setDurationCount(duration);
        }
        setDateStart(values?.startDate.format(FORMAT_DATE_FOR_SAVE));
    }, [values?.startDate, values?.endDate]);

    useEffect(() => {
        form.setFieldsValue({ endDate: dayjs(dateEnd) });
    }, [dateEnd]);

    useEffect(() => {
        form.setFieldsValue({ duration: durationCount });
    }, [durationCount]);
};

export default useHandleDateRange2;
