import { DialogTitle } from '@mui/material';
import { Stack } from '@mui/material';
import { debounce } from '@mui/material/utils';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import { nanoid } from '@reduxjs/toolkit';
import { Flex, Modal } from 'antd';
import {
    Document,
    Markup
} from 'interfaces/documents';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Moment from 'react-moment';
import {
    clearMarkup,
    reset,
} from 'slices/documentSlice';
import {
    fetchDocument,
    fetchMarkup,
    importMarkup,
    saveMarkup,
    updateDocument
} from 'slices/documentsActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import {
    Button,
    Space,
    Tag,
    Typography,
    Progress,
} from 'antd';
import {
    ArrowLeftOutlined,
    FileOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import { closeModal } from 'slices/modalsSlice';

export interface IDocumentMarkupModalProps {
    readonly?: boolean;
};

export interface IDocumentMarkupModalCallbacks {
    onSubmit?: () => void;
    onClose?: () => void;
};

const DocumentMarkupModal = ({ id, zIndex, props, callbacks }: { id: string, zIndex: number, props: IDocumentMarkupModalProps, callbacks: IDocumentMarkupModalCallbacks }) => {
    const dispatch = useAppDispatch();
    const { readonly } = props;
    const { onSubmit, onClose } = callbacks;
    const modal = useRef<BaseModalRef>(null);
    const { user } = useAppSelector((state) => state.account);
    const { document, markup, loading, error } = useAppSelector((state) => state.document);
    const webViewerRef = useRef<HTMLDivElement>(null);
    const [webViewer, setWebViewer] = useState<null | WebViewerInstance>(null);
    const [webViewerId, setWebViewerId] = useState('document_markup_viewer_' + nanoid());
    const [doesWebViewerListen, setDoesWebViewerListen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [hasPagesChanged, setHasPagesChanged] = useState(false);
    const [hasAnnotationsChanges, setHasAnnotationsChanges] = useState(false);
    const [documentUpdatedAt, setDocumentUpdatedAt] = useState<null | number>(null);

    useEffect(() => {
        if (id) {
            setIsOpen(true);
        }
    }, [id]);

    useEffect(() => {
        /**
         * Only when opened
         */
        if (!isOpen) return;
        /**
         * Init WebViewer once, if DOM exists.
         * It`s expensive so we don't want to repeat it every time.
         */
        if (!webViewer) {
            if (!webViewerRef.current) return;
            makeWebViewer(webViewerRef.current as HTMLElement);
        }
        /**
         * Fetch Document URL
         * We did setDocument before open this modal,
         * but this Document doesn't have URL,
         * because the generation of temporary AWS URL is time consuming,
         * so we do that on demand.
         */
        if (!document?.url) {
            dispatch(fetchDocument(document?.id));
        }
    }, [isOpen]);

    useEffect(() => {
        if (!webViewer) return;
        if (!document) return;
        /**
         * WebViewer is ready, add event listeners and more.
         */
        if (!doesWebViewerListen) {
            addListeners(webViewer as WebViewerInstance, document);
            defineMode(webViewer as WebViewerInstance, document);
        }
        /**
         * WebViewer is ready and Document URL is ready, load document into the WebViewer.
         */
        if (document.url) {
            loadDocument(webViewer, document);
        }
        if (document.has_markup) {
            setHasAnnotationsChanges(true);
        }
    }, [webViewer, document]);

    useEffect(() => {
        if (!webViewer) return;
        if (!markup) return;
        /**
         * Markup is ready, import markup into the WebViewer.
         */
        setIsLoaded(true);
        console.log('markup', markup);
        importAnnotations(webViewer, markup);

    }, [webViewer, markup]);

    /**
     * Init the WebViewer.
     *
     * @param {HTMLElement} webViewerEl - The HTML element to render the WebViewer in.
     */
    const makeWebViewer = (webViewerEl: HTMLElement) => {
        if (webViewer) return;

        WebViewer({
            path: '/webviewer/lib',
            isAdminUser: false,
            isReadOnly: true,
            annotationUser: user?.name || 'Guest',
            useDownloader: false,
            preloadWorker: 'all',
            enableOptimizedWorkers: true,
            enableOfficeEditing: false,
            disableLogs: true,
            licenseKey: process.env.REACT_APP_APRYSE_LICENSE,
            fullAPI: true,
        }, webViewerEl)
            .then(instance => {
                /**
                 * Make instance available as state.
                 */
                setWebViewer(instance);
            });
    };

    /**
     * Loads a document into the WebViewer.
     *
     * @param {Document} document - The document to load.
     */
    const loadDocument = (webViewer: WebViewerInstance, document: Document) => {
        webViewer.UI.loadDocument(document.url, {
            filename: document.name,
            extension: document.extension
        });
    };

    /**
     * Import markups from a markup object into the annotation manager of the WebViewer.
     *
     * @param {Markup} markup - The markup object containing the markups to import.
     */
    const importAnnotations = (webViewer: WebViewerInstance, markup: Markup) => {
        if (!markup.xfdf) return;

        webViewer.Core.annotationManager.importAnnotations(markup.xfdf);
    };

    /**
     * Debounce saveMarkup
     */
    const handleSaveMarkup = useMemo(() => {
        return debounce((xfdfData: string) => {
            dispatch(saveMarkup({
                id: document?.id,
                data: {
                    xfdf: xfdfData,
                }
            }));
        }, 800);
    }, [document]);

    /**
     * Cached callback of updateDocument action
     */
    const _updateDocument = useCallback((formData: any) => {
        return updateDocument({
            id: document?.id,
            file: formData,
        });
    }, [document]);

    /**
     * Cached callback of fetchMarkup action
     */
    const _fetchMarkup = useCallback(() => {
        return fetchMarkup(document?.id);
    }, [document]);

    /**
     * Cached callback of importMarkup action
     */
    const _importMarkup = useCallback(() => {
        return importMarkup(document?.id);
    }, [document]);


    const isReadOnlyType = (document: Document) => {
        if (document.mime_type.includes('pdf')) return false;
        // if (document.mime_type.includes('image')) return false;

        return true;
    };

    /**
     * Get document and annotations as binary
     */
    const annotationsToFormData = async (webViewer: WebViewerInstance) => {
        const xfdfString = await webViewer.Core.annotationManager.exportAnnotations();
        const fileData = await webViewer.Core.documentViewer.getDocument().getFileData({ xfdfString });
        const formData = new FormData();
        formData.append('file', new Blob([
            new Uint8Array(fileData),
        ], {
            type: 'application/pdf',
        }));
        formData.append('file_props', JSON.stringify({
            force_replace: false,
        }));

        return formData;
    };

    /**
     * Adds event listeners to the WebViewer instance.
     *
     * @param {WebViewerInstance} webViewer - The WebViewer instance.
     */
    const addListeners = async (webViewer: WebViewerInstance, document: Document) => {
        /**
         * Load Markups
         */
        webViewer.Core.documentViewer.addEventListener('documentLoaded.custom', () => {
            if (!document.has_markup) return;
            dispatch(_fetchMarkup());
        });

        /**
         * Save Markups
         */
        if (!props.readonly) {
            if (!isReadOnlyType(document)) {
                webViewer.Core.annotationManager.addEventListener('annotationChanged.custom', async (annotations, action, params) => {
                    if (params?.imported) return;

                    const xfdfString = await webViewer.Core.annotationManager.exportAnnotations();
                    handleSaveMarkup(xfdfString);

                    setHasAnnotationsChanges(true);
                    setDocumentUpdatedAt(Date.now());
                });
                webViewer.Core.documentViewer.addEventListener('pagesUpdated.custom', async (e) => {
                    setHasPagesChanged(true);
                    setDocumentUpdatedAt(Date.now());
                });
            }
        }

        setDoesWebViewerListen(true);
    };

    /**
     * Define the Read-only Mode of WebViewer instance based on Props.
     *
     * @param {WebViewerInstance} webViewer - The WebViewer instance.
     */
    const defineReadOnly = (webViewer: WebViewerInstance, document: Document) => {
        if (props.readonly) {
           webViewer.Core.documentViewer.enableReadOnlyMode();
           webViewer.Core.annotationManager.enableReadOnlyMode();
           setIsReadOnly(true);
        } else {
            /**
             * Allow markups only for PDF and Images
             */
            if (!isReadOnlyType(document)) {
                webViewer.Core.documentViewer.disableReadOnlyMode();
                webViewer.Core.annotationManager.disableReadOnlyMode();
                setIsReadOnly(false);
            } else {
                webViewer.Core.documentViewer.enableReadOnlyMode();
                webViewer.Core.annotationManager.enableReadOnlyMode();
                setIsReadOnly(true);
            }
        }
    };

    const defineMode = (webViewer: WebViewerInstance, document: Document) => {
        webViewer.UI.disableReplyForAnnotations(function(annotation) {
            return true;
        });
        webViewer.UI.disableElements([
            'filterAnnotationButton',
            'multiSelectModeButton',
            'notePopup',
            'noteState',
        ]);
        if (props.readonly || isReadOnlyType(document)) {
            webViewer.Core.documentViewer.enableReadOnlyMode();
            webViewer.Core.annotationManager.enableReadOnlyMode();
            viewOnlyMode(webViewer, document);
            setIsReadOnly(true);
        } else {
            webViewer.Core.documentViewer.disableReadOnlyMode();
            webViewer.Core.annotationManager.disableReadOnlyMode();
            liteMode(webViewer, document);
            setIsReadOnly(false);
        }
    };

    /**
     * View Only Mode
     */
    const viewOnlyMode = (webViewer: WebViewerInstance, document: Document) => {
        webViewer.UI.disableElements([
            webViewer.UI.ToolbarGroup.VIEW,

            'downloadButton',
            'leftPanelButton',
            'settingsButton',
            'squigglyToolGroupButton',
            'stickyToolGroupButton',
            'toolsOverlay',
            'viewControlsButton',
            'notesPanel',
            'panToolButton',
            'ribbons',
            'selectToolButton',
            'toggleNotesButton',
        ]);
    };

    /**
     * Lite Mode
     */
    const liteMode = (webViewer: WebViewerInstance, document: Document) => {
        webViewer.UI.disableElements([
            webViewer.UI.ToolbarGroup.FILL_AND_SIGN,
            webViewer.UI.ToolbarGroup.FORMS,
            webViewer.UI.ToolbarGroup.INSERT,

            'downloadButton',
            'leftPanelButton',
            'settingsButton',
            'squigglyToolGroupButton',
            'stickyToolGroupButton',
            'toolsOverlay',
            'viewControlsButton',
        ]);

        if (document.extension === 'pdf') {
            webViewer.UI.disableElements([
                webViewer.UI.ToolbarGroup.EDIT,
            ]);

            webViewer.UI.setHeaderItems(function(header) {
                header.getHeader('toolbarGroup-Annotate').delete(3);
                header.getHeader('toolbarGroup-Annotate').delete(5);
            });
        } else if (document.extension === 'jpg' || document.extension === 'png' || document.extension === 'jpeg') {
            webViewer.UI.disableElements([
                'highlightToolGroupButton',
                'strikeoutToolGroupButton',
                'underlineToolGroupButton',
            ]);

            webViewer.UI.setHeaderItems(function(header) {
                var editGroup = header.getHeader('toolbarGroup-Edit');
                editGroup.pop();
                editGroup.push({
                    type: 'actionButton',
                    title: 'Rotate',
                    img: 'icon-header-page-manipulation-page-rotation-clockwise-line',
                    onClick: async function() {
                        await webViewer.Core.documentViewer.getDocument().rotatePages([1], 1);
                    }
                });
                editGroup.push({ type: 'spacer' });

                header.getHeader('toolbarGroup-Annotate').delete(1);
                header.getHeader('toolbarGroup-Annotate').delete(2);
                header.getHeader('toolbarGroup-Annotate').delete(4);
                header.getHeader('toolbarGroup-Annotate').delete(3);
            });
        }
    };

    /**
     * Medium Mode
     */
    const mediumMode = (webViewer: WebViewerInstance, document: Document) => {
        webViewer.UI.disableElements([
            webViewer.UI.ToolbarGroup.FILL_AND_SIGN,
            webViewer.UI.ToolbarGroup.FORMS,

            'downloadButton',
            'leftPanelButton',
            'settingsButton',
            'squigglyToolGroupButton',
            'stickyToolGroupButton',
            'viewControlsButton',
        ]);

        if (document.extension === 'pdf') {
            webViewer.UI.disableElements([
                webViewer.UI.ToolbarGroup.EDIT,
            ]);

            webViewer.UI.setHeaderItems(function(header) {
                header.getHeader('toolbarGroup-Annotate').delete(3);
                header.getHeader('toolbarGroup-Annotate').delete(5);
            });
        } else if (document.extension === 'jpg' || document.extension === 'png' || document.extension === 'jpeg') {
            webViewer.UI.disableElements([
                'highlightToolGroupButton',
                'strikeoutToolGroupButton',
                'underlineToolGroupButton',
            ]);

            webViewer.UI.setHeaderItems(function(header) {
                var editGroup = header.getHeader('toolbarGroup-Edit');
                editGroup.pop();
                editGroup.push({
                    type: 'actionButton',
                    title: 'Rotate',
                    img: 'icon-header-page-manipulation-page-rotation-clockwise-line',
                    onClick: async function() {
                        await webViewer.Core.documentViewer.getDocument().rotatePages([1], 1);
                    }
                });
                editGroup.push({ type: 'spacer' });

                header.getHeader('toolbarGroup-Annotate').delete(1);
                header.getHeader('toolbarGroup-Annotate').delete(2);
                header.getHeader('toolbarGroup-Annotate').delete(4);
                header.getHeader('toolbarGroup-Annotate').delete(3);
            });
        }
    };

    /**
     * Full Mode
     */
    const fullMode = (webViewer: WebViewerInstance, document: Document) => {
    };

    /**
     * Removes the event listeners from the WebViewer instance.
     *
     * @param {WebViewerInstance} webViewer - The WebViewer instance from which event listeners will be removed.
     */
    const removeListeners = (webViewer: WebViewerInstance) => {
        webViewer.Core.documentViewer.removeEventListener('documentLoaded.custom');
        webViewer.Core.documentViewer.removeEventListener('pagesUpdated.custom');
        webViewer.Core.annotationManager.removeEventListener('annotationChanged.custom');
        setDoesWebViewerListen(false);
    };

    /**
     * Handles the action when the close event is triggered.
     */
    const handleOnClose = () => {
        if (document) {
            /**
             * Reset the state
             */
            dispatch(reset());
        }

        if (webViewer) {
            /**
             * Close opened document,
             * so it won't be shown on the next opening
             * as WebViewer will be already initialized.
             */
            webViewer.UI.closeDocument();

            /**
             * Remove listeners to make sure WebViewer works with the right Document.
             */
            removeListeners(webViewer);
        }

        /**
         * Reset autosave time
         */
        setHasAnnotationsChanges(false);
        setHasPagesChanged(false);
        setDocumentUpdatedAt(null);

        onClose?.();
        close();
    };

    /**
     * Save
     */
    const handleSave = async () => {
        if (!webViewer) return;
        if (!document) return;

        if (hasPagesChanged) {
            const formData = await annotationsToFormData(webViewer);
            dispatch(_updateDocument(formData)).then(() => {
                loadDocument(webViewer, document);
            });
        } else if (hasAnnotationsChanges) {
            dispatch(_importMarkup()).then(() => {
                dispatch(clearMarkup());
            });
        }
        /**
         * Reset autosave time
         */
        setHasAnnotationsChanges(false);
        setHasPagesChanged(false);
        setDocumentUpdatedAt(null);
    };

    /**
     * Close the modal
     */
    const close = () => {
        dispatch(closeModal({ modal: 'documentMarkupModal', id }));
    };

    return (
        <Modal
            zIndex={zIndex}
            title={
                <Flex style={{ position: "relative" }}>
                    <Space style={{ width: '100%', justifyContent: 'space-between', paddingBottom: 15 }}>
                        <Space align="center">
                            <Button
                                type="primary"
                                icon={<ArrowLeftOutlined />}
                                onClick={close}
                                style={{ marginRight: 10 }}
                            />
                            {isReadOnly ? 'View document' : 'Markup document'}
                            <Tag icon={<FileOutlined />} color="blue">{document?.name}</Tag>
                        </Space>
                        {!isReadOnly && (
                            <Space align="center">
                                {(hasPagesChanged || hasAnnotationsChanges) && (
                                    <Typography.Text>
                                        Unsaved changes {documentUpdatedAt && (
                                            <span>(<Moment fromNow>{documentUpdatedAt}</Moment>)</span>
                                        )}
                                    </Typography.Text>
                                )}
                                <Button
                                    type="primary"
                                    icon={<SaveOutlined />}
                                    onClick={() => handleSave()}
                                    disabled={!(hasPagesChanged || hasAnnotationsChanges) || loading}
                                >
                                    Save
                                </Button>
                            </Space>
                        )}
                    </Space>
                    {loading && <Progress
                        percent={100}
                        showInfo={false}
                        status="active"
                        style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            width: '100%'
                        }}
                    />}
                </Flex>
            }
            open={isOpen}
            onCancel={handleOnClose}
            closable={false}
            footer={null}
            style={{ padding: 0 }}
            width="100%"
            centered
        >
            <div
                id={webViewerId}
                ref={webViewerRef}
                style={{
                    width: '100%',
                    height: 'calc(100vh - 80px)'
                }}
            />
        </Modal>
    );
};

export default DocumentMarkupModal;
