import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Button, message, Upload, Modal } from 'antd';
import { getApiUrl, getAccessToken, useAxios } from 'utilities/axios';
import { useAppSelector } from 'utilities/hooks';

const ImportPage = () => {
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [importErrors, setImportErrors] = useState<Array<{ row: number; error: string }>>([]);

    const apiUrl = getApiUrl(currentTenant);
    const accessToken = getAccessToken();
    const axios = useAxios(accessToken, currentTenant);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChange: UploadProps['onChange'] = (info) => {
        if (info.file.status === 'uploading') {
            // console.log(info.file, info.fileList);
            setImportErrors([]);
            setFileList([info.file]);
        }

        if (info.file.status === 'done') {
            message.success(`${info.file.name} imported successfully.`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} import failed.`);
        }

        if (info.file.status === 'done' || info.file.status === 'error') {
            setFileList([]);
            // console.log(info.file.response);
            if (info.file.response && info.file.response.success === false) {
                setImportErrors(info.file.response.errors);
                showModal();
            }
        }
    };

    const handleExport = async () => {
        const response = await axios.get(`api/v1/export/users`, {
            responseType: 'blob',
        });

        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'users_export.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const props = {
        action: `${apiUrl}/api/v1/import/users`,
        headers: {
            Authorization: 'Bearer ' + accessToken,
        },
        onChange: handleChange,
        multiple: false,
    };

    return (
        <>
            <Upload {...props} fileList={fileList}>
                <Button icon={<UploadOutlined />}>Import Users</Button>
            </Upload>
            <br />
            <Button onClick={handleExport}>Export Users</Button>
            <Modal title="Import Errors" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                {importErrors.map((error) => {
                    return (
                        <div key={error.row}>
                            Row {error.row}: {error.error}
                        </div>
                    );
                })}
            </Modal>
        </>
    );
};

export default ImportPage;
