import { Flex, TableProps, Typography } from "antd";
import Moment from "react-moment";
import { deleteDocumentVersion, fetchDocumentVersions } from "slices/documentsActions";
import { setDocumentVersion } from "slices/documentsActions";
import { useAppDispatch } from "utilities/hooks";
import { useModal } from "hooks/useModal";
import { Document } from "interfaces/documents";
import { CheckCircleOutlined, DeleteFilled, SelectOutlined } from "@ant-design/icons";

const TableColumns = ({ document }: { document: Document }) => {
    const dispatch = useAppDispatch();
    const { openModal } = useModal();

    const handleConfirmRowSetLatest = (item: any) => {
        dispatch(setDocumentVersion({
            id: document?.id,
            version: item?.id
        }))
            .unwrap()
            .then(() => {
                dispatch(fetchDocumentVersions(document?.id));
            })
            .catch();
    };

    const handleRowSetLatest = (item: any) => {
        openModal('confirmationModal', {
            text: 'Are you sure want to set this version as latest?',
        }, {
            onConfirm: () => {
                handleConfirmRowSetLatest(item);
            }
        });
    };

    const handleConfirmRowDelete = (record: any) => {
        if (!document) return;
        if (!record) return;

        dispatch(deleteDocumentVersion({
            id: document?.id,
            version: record?.id
        }))
            .unwrap()
            .then(() => {
                dispatch(fetchDocumentVersions(document?.id));
            })
            .catch();
    };

    const handleRowDelete = (record: any) => {
        openModal('confirmationModal', {
            text: 'Are you sure want to delete version?',
        }, {
            onConfirm: () => {
                handleConfirmRowDelete(record);
            }
        });
    };

    const columns: TableProps<any>['columns'] = [
        {
            title: 'Last Modified',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (_, record) => <Moment date={record.updated_at} format="lll" />
        },
        {
            title: 'User',
            dataIndex: 'metadata.user.name',
            key: 'metadata.user.name',
            render: (_, record) => <Typography>{record?.metadata?.user?.name}</Typography>
        },
        {
            title: 'Size',
            dataIndex: 'size_formatted',
            key: 'size_formatted',
        },
        {
            title: 'Is Latest',
            dataIndex: 'is_latest',
            key: 'is_latest',
            render: (_, record) => {
                return record.is_latest ? <CheckCircleOutlined style={{ fontSize: 20 }} /> : <></>;
            }
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => {
                if (record.is_latest) return <></>;

                return <Flex
                    gap={2}
                    className="hidden"
                    align="center"
                    justify="space-between"
                >
                    <SelectOutlined
                        onClick={() => {
                            handleRowSetLatest(record);
                        }}
                    />
                    <DeleteFilled
                        onClick={() => {
                            handleRowDelete(record);
                        }}
                    />
                </Flex>;
            }
        }
    ];

    return columns;
}

export default TableColumns;
