import { Button, Flex } from "antd";

import { User } from "interfaces/users";
import { createGuestTeam, softDeleteGuestTeam, updateGuestTeamMembers } from "slices/teamsActions";
import { useAppDispatch, useAppSelector } from "utilities/hooks";
import { Invitation } from "interfaces/invitations";
import { syncInvitations } from "slices/invitationsActions";
import { useModal } from "hooks/useModal";

const Actions = ({
    close,
    onSubmit,
    role,
    membersSelected = [],
    invitationsSelected = [],
    entity,
    currentTab,
}: {
    close: () => void,
    onSubmit: () => void,
    role: string,
    membersSelected?: any[],
    invitationsSelected?: any[],
    entity: any,
    currentTab: string,
}) => {
    const dispatch = useAppDispatch();
    const { openModal } = useModal();
    const entityId = entity?.type === 'category' ? entity?.uuid : entity?.id;
    const {
        loading: teamLoading,
        members: teamMembers,
        membersMap: teamMembersMap
    } = useAppSelector((state) => state.team);
    const {
        loading: invitationsLoading,
        invitations
    } = useAppSelector((state) => state.invitations);

    const handleRemoveAccess = () => {
        openModal('confirmationModal', {
            text: 'Are you sure want to stop sharing?',
        }, {
            onConfirm: handleConfirmRemoveAccess
        });
    };

    const handleConfirmRemoveAccess = () => {
        dispatch(softDeleteGuestTeam(entityId))
            .unwrap()
            .then(() => {
                onSubmit();
                close();
            }).catch(() => { });
    };

    const handleSave = () => {
        let promises: any[] = [];
        let data: any = {
            members: teamMembers || [],
            invitations: invitations || [],
        };

        if (currentTab === 'members') {
            data.members = data.members.concat(membersSelected);
        } else if (currentTab === 'invitations') {
            data.invitations = data.invitations.concat(invitationsSelected);
        }
        data.members = data.members.map((member: User & { value?: string }) => {
            return {
                id: member.value || member.id,
                role: teamMembersMap[member.id]?.role || role,
            };
        });
        data.invitations = data.invitations.map((invitation: Invitation) => {
            return {
                id: invitation.id,
                email: invitation.email,
                phone: invitation.phone,
                team_role: invitation.team_role || role,
            };
        });

        data.entityId = entityId;
        data.teamId = entityId;
        if (teamMembers?.length) {
            promises.push(updateGuestTeamMembers);
        } else {
            data.entity = {
                id: entityId,
                type: entity?.type,
            };
            promises.push(createGuestTeam);
        }

        if (invitations?.length) {
            promises.push(syncInvitations);
        }

        promises.forEach(promise => {
            dispatch(promise(data)).unwrap().then(() => {
                onSubmit();
                close();
            });
        });
    };

    return (
        <>
            <Flex style={{ width: "100%" }}>
                {!!(teamMembers?.length || invitations?.length) && (
                    <Button
                        color="danger"
                        variant="outlined"
                        onClick={handleRemoveAccess}
                        loading={teamLoading || invitationsLoading}
                    >
                        Stop Sharing
                    </Button>
                )}
                <Flex style={{ marginLeft: 'auto' }}>
                    <Button type="text" onClick={close}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSave}
                        loading={teamLoading || invitationsLoading}
                    >
                        Save
                    </Button>
                </Flex>
            </Flex>
        </>
    )
};

export default Actions;
