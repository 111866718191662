import { AppBar, Toolbar } from '@mui/material';
import { RefObject, useEffect, useState } from 'react';
import { fetchAccount } from 'slices/accountActions';
import { useAppDispatch } from 'utilities/hooks';
import { navbar } from 'components/DefaultAppBar/styles';
import { setTransparentNavbar, useSitemaxUIController } from 'context';
import DrawerTenantList from './DrawerTenantList';
import AvatarIconBar from './AvatarIconBar';
import { Flex } from 'antd';
import TenantLabelBar from './TenantLabelBar';
import FilterProjectBar from './FilterProjectBar';

interface IDefaultAppBar {
    absolute?: boolean;
    light?: boolean;
    parentRef: RefObject<HTMLDivElement>;
}

const DefaultAppBar: React.FC<IDefaultAppBar> = ({ absolute = false, light = false, parentRef }) => {
    const dispatch = useAppDispatch();
    const [controller, dispatchUI] = useSitemaxUIController();

    const [navbarType, setNavbarType] = useState<'sticky' | 'static'>();
    const { transparentNavbar, fixedNavbar } = controller;

    useEffect(() => {
        if (fixedNavbar) {
            setNavbarType('sticky');
        } else {
            setNavbarType('static');
        }

        // NOTE: A function that sets the transparent state of the navbar.
        const handleTransparentNavbar = () => {
            setTransparentNavbar(dispatchUI, (fixedNavbar && parentRef.current?.scrollTop === 0) || !fixedNavbar);
        };
        // The event listener that's calling the handleTransparentNavbar function when scrolling the window.
        parentRef.current?.addEventListener('scroll', handleTransparentNavbar);
        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();
        // Remove event listener on cleanup
        return () => parentRef.current?.removeEventListener('scroll', handleTransparentNavbar);
    }, [dispatchUI, fixedNavbar]);

    useEffect(() => {
        dispatch(fetchAccount());
    }, []);

    return (
        <>
            <AppBar
                position={absolute ? 'absolute' : navbarType}
                color="inherit"
                sx={(theme: any) => navbar(theme, { transparentNavbar, absolute, light })}
            >
                <Toolbar>
                    <Flex justify={'space-between'} style={{ width: '100%' }} align={'center'}>
                        <Flex flex={20} justify={'left'} align={'center'} gap={20}>
                            <TenantLabelBar />
                            <FilterProjectBar />
                        </Flex>
                        <Flex flex={1} justify={'right'} align={'center'}>
                            <AvatarIconBar />
                        </Flex>
                    </Flex>
                </Toolbar>
            </AppBar>
            <DrawerTenantList />
        </>
    );
};

export default DefaultAppBar;
