import { ProjectSelect } from 'smx-components';
import { useAppDispatch } from '../../utilities/hooks';
import { setterApp } from '../../slices/appSlice';

const FilterProjectBar = () => {
    const dispatch = useAppDispatch();

    const projectSelectHandler = (value: string | string[]) => {
        setTimeout(() => {
            dispatch(setterApp({ globalFilterProject: value as string[] }));
        });
    };

    return (
        <>
            <ProjectSelect
                filterList={[]}
                style={{ width: '60%' }}
                mode={'multiple'}
                onChange={projectSelectHandler}
                maxTagCount={'responsive'}
            />
        </>
    );
};

export default FilterProjectBar;
