import {
    forwardRef,
    useEffect,
    useRef,
    useState
} from 'react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { useLocation } from 'react-router-dom';
import { deleteClassification, fetchClassifications } from 'modules/settings/slices/classificationsActions';
import { Classification, Classifications } from 'modules/settings/interfaces/classifications';
import { BaseModalRef } from 'modals/BaseModal';
import { Button, ListItemIcon } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import ClassificationModal from '../ClassificationModal/ClassificationModal';
import { SitemaxTable } from 'containers/SitemaxTable/SitemaxTable';
import WTypography from 'containers/WTypography';
import { ListItemsQuery, TableDB } from 'smx-components';

export const labelClassificationsTab = 'Classifications';

const ClassificationsTab = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { classifications, loading } = useAppSelector((state) => state.classifications);
    const classificationModal = useRef<BaseModalRef>(null);

    useEffect(() => {
        dispatch(fetchClassifications(null));
    }, [location, currentTenant]);

    const handleCreate = () => {
        dispatch(fetchClassifications(null));
    };

    const handleDelete = (row: any) => {
        dispatch(deleteClassification(row.id));
        dispatch(fetchClassifications(null));
    };

    const headers = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params: { row?: Classification }) => {
                return <><WTypography sx={{ pl: 2 }}>{params.row?.name}</WTypography></>;
            },
        },
        {
            actions: [
                {
                    title: 'Edit',
                    onClick: (row: any) => {
                        classificationModal?.current?.open(row);
                    },
                    icon: <ListItemIcon>
                        <Edit fontSize="small" />
                    </ListItemIcon>,
                },
                {
                    title: 'Delete',
                    onClick: (row: any) => {
                        handleDelete(row);
                    },
                    icon: <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>,
                },
            ],
        },
    ];

    return <>
        <Button
            variant="contained"
            disableElevation
            onClick={() => classificationModal?.current?.open()}
            startIcon={<Add />}
            sx={{ marginBottom: 2 }}
        >
            Create Classificiation
        </Button>
        <SitemaxTable
            headers={headers}
            rows={classifications}
            getData={() => {
                dispatch(fetchClassifications(null));
            }}
            loading={loading}
        ></SitemaxTable>

        {/* <TableDB<
            ListItemsQuery$variables,
            Partial<Flatten<ItemFragment$data>>,
            OnlyObjectKeys<ItemFragment$data>
        >
            query={ListItemsQuery}
            fragmentList={ListItemFragment}
            fragmentConnection={ListItemConnectionFragment}
            fragment={[ItemFragment]}
            mutationCreate={CreateListItemMutation}
            mutationUpdate={UpdateListItemMutation}
            mutationDelete={DeleteListItemMutation}
            nameQuery={'listItems'}
            columnsLabel={{
                name: 'Name',
                code: 'Code',
            }}
            varRequest={{ type: 'cost_code' }}
            actions={['createNew', 'edit', 'delete']}
        /> */}
        <ClassificationModal
            ref={classificationModal}
            onSubmit={handleCreate}
        ></ClassificationModal>
    </>;
};

export default forwardRef(ClassificationsTab);
