import { ShareAltOutlined } from '@ant-design/icons';
import { Category, Document } from 'interfaces/documents';
import { useModal } from 'hooks/useModal';

export const Sharing = (
    row: Category | Document,
    fetchData: () => void,
) => {
    const { openModal } = useModal();

    return {
        key: 'Sharing',
        label: 'Sharing',
        onClick: () => {
            openModal('guestTeamModal', {
                entity: row,
            }, {
                onSubmit: () => {
                    fetchData();
                },
                onClose: () => {
                    fetchData();
                },
            });
        },
        icon: <ShareAltOutlined />,
    };
}