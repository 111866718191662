import {
    Box,
    Grid,
} from '@mui/material';
import CustomBreadCrumbs from 'components/CustomBreadCrumbs';
import { useModal } from 'hooks/useModal';
import { BaseModalRef } from 'modals/BaseModal';
import DocumentMarkupModal from 'modals/DocumentMarkupModal';
import DocumentsTable from 'modules/documents/components/DocumentsTable';
import {
    useRef,
    useState
} from 'react';
import {
    useNavigate
} from 'react-router-dom';
import {
    reset
} from 'slices/documentsSlice';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';


const DocumentsTrashPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { loading } = useAppSelector((state) => state.documents);

    const handleBreadcrumbClick = () => {
        dispatch(reset());
        navigate('/documents/trash');
    };

    return <>
        <Box id="notification-bar"></Box>
        <Grid item xs={12} sx={{py: 3}}>
            <CustomBreadCrumbs
                title={'Deleted files'}
                loading={loading}
                breadcrumbs={[]}
                onRootClick={() => handleBreadcrumbClick()}
            />
        </Grid>

        <DocumentsTable deleted />
    </>;
};

export default DocumentsTrashPage;