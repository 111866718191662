import { Modal, Typography } from 'antd';
import {
    useEffect,
    useState
} from 'react';
import { closeModal } from 'slices/modalsSlice';
import { useAppDispatch } from 'utilities/hooks';

// must be unique
export const modalName = 'confirmationModal';

// serializable
export interface IConfirmationModalProps {
    title?: string;
    text?: string;
    confirmLabel?: string;
    cancelLabel?: string;
}

// non serializable
export interface IConfirmationModalCallbacks {
    onConfirm?: () => void;
    onCancel?: () => void;
}

const ConfirmationModal = ({ id, zIndex, props, callbacks }: { id: string, zIndex: number, props: IConfirmationModalProps, callbacks: IConfirmationModalCallbacks }) => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (props && !open) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [props]);

    const handleConfirm = () => {
        callbacks.onConfirm?.();
        handleCloseModal();
    };

    const handleCancel = () => {
        callbacks.onCancel?.();
        handleCloseModal();
    };

    const handleCloseModal = () => {
        dispatch(closeModal({ modal: modalName, id }));
    };

    return (
        <Modal
            open={open}
            title={props?.title || 'Confirmation'}
            onOk={handleConfirm}
            onCancel={handleCancel}
            okText={props?.confirmLabel || 'Confirm'}
            cancelText={props?.cancelLabel || 'Cancel'}
            zIndex={zIndex}
        >
            <Typography>{props?.text}</Typography>
        </Modal>
    );
};

export default ConfirmationModal;