import { forwardRef, Ref } from 'react';

import WBoxRoot from 'containers/WBox/WBoxRoot';

const WBoxComponent = forwardRef(function WBox(
    {
        variant = 'contained',
        bgColor = 'transparent',
        color = 'dark',
        opacity = 1,
        borderRadius = 'none',
        shadow = 'none',
        ...rest
    }: any,
    ref: Ref<any>,
) {
    return <WBoxRoot {...rest} ref={ref} ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow }} />;
});

export default WBoxComponent;
