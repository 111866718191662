import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { Category, Document } from 'interfaces/documents';
import { useModal } from 'hooks/useModal';
import { copyDocument } from 'slices/documentsActions';
import { useAppDispatch } from 'utilities/hooks';
import { useEffect } from 'react';
import { setExcluded } from 'slices/categoriesSlice';

export const Copy = (
    row: Category | Document,
    fetchData: () => void
) => {
    const dispatch = useAppDispatch();
    const { openModal } = useModal();
    const copyItem = (destination: Category | null) => {
        dispatch(copyDocument({
            id: (row as Document).id,
            category_id: destination?.id || null,
        }));

        fetchData();
    };

    useEffect(() => {
        if (!row) return;

        if (row.type === 'category') {
            dispatch(setExcluded([row as Category]));
        }
    }, [row]);

    return {
        key: 'Copy',
        label: 'Copy',
        onClick: () => {
            openModal('categoriesTreeModal', {
                title: 'Copy to...',
                submitLabel: 'Copy',
                cancelLabel: 'Cancel',
            }, {
                onSubmit: (value: Category|null) => {
                    copyItem(value);
                }
            });
        },
        icon: <CopyOutlined />,
    };
}