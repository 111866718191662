import { useSelector } from 'react-redux';
import { RootState } from '../store';

const useSwitchScheduleTabs = () => {
    const schedulePageView = useSelector((state: RootState) => state.schedule.schedulePageView);

    const getTabKey = () => {
        if (schedulePageView === 'timeline') return '2';
        if (schedulePageView === 'board') return '4';
        if (schedulePageView === 'listItem') return '0';
        if (schedulePageView === 'listTask') return '1';
        if (schedulePageView === 'schedule') return '3';
        return '0';
    };

    return getTabKey();
};

export default useSwitchScheduleTabs;
