import { Button, Modal, Typography } from 'antd';
import {
    useEffect,
    useState
} from 'react';
import { closeModal } from 'slices/modalsSlice';
import { useAppDispatch } from 'utilities/hooks';

export interface IResolveUploadModalProps {};

export interface IResolveUploadModalCallbacks {
    onResolve?: (forceRename: boolean) => void;
    onClose?: () => void;
};

const ResolveUploadModal = ({ id, zIndex, props, callbacks }: { id: string, zIndex: number, props: IResolveUploadModalProps, callbacks: IResolveUploadModalCallbacks }) => {
    const dispatch = useAppDispatch();
    const { onResolve, onClose } = callbacks;
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!id) return;
        setIsOpen(true);
    }, [id]);

    const handleClose = () => {
        onClose?.();
        close();
    };

    const handleResolve = (forceRename: boolean = false) => {
        onResolve?.(forceRename);
        close();
    };

    const close = () => {
        dispatch(closeModal({ modal: 'resolveUploadModal', id }));
    };

    return (
        <Modal
            zIndex={zIndex}
            title={'Resolve upload files'}
            open={isOpen}
            onClose={handleClose}
            footer={
                <>
                    <Button
                        color="primary"
                        variant="solid"
                        onClick={() => handleResolve(false)}
                    >
                        Replace as New Versions
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => handleResolve(true)}
                    >
                        Rename and Keep All
                    </Button>
                    <Button
                        color="default"
                        onClick={close}
                    >
                        Cancel
                    </Button>
                </>
            }
        >
            <Typography>We found that one or multiple files have same names. How do you want to proceed?</Typography>
        </Modal>
    );
};

export default ResolveUploadModal;