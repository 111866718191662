import React, { createContext, useRef } from 'react';
import { IConfirmationModalCallbacks } from 'modals/ConfirmationModal';
import { ICategoriesTreeModalCallbacks } from 'modals/CategoriesTreeModal';
import { ModalNamesType } from 'slices/modalsSlice';
import { IRenameModalCallbacks } from 'modals/RenameModal';
import { IDocumentVersionsModalCallbacks } from 'modals/DocumentVersionsModal';
import { IGuestTeamModalCallbacks } from 'modals/GuestTeamModal';
import { ITenantCreateModalCallbacks } from 'modals/TenantCreateModal';
import { IResolveUploadModalCallbacks } from 'modals/ResolveUploadModal';
import { IHistoryModalCallbacks } from 'modals/HistoryModal';
import { ICategoryCreateModalCallbacks } from 'modals/CategoryCreateModal';
import { IDocumentMarkupModalCallbacks } from 'modals/DocumentMarkupModal';

export type ModalCallbacksType =
    IConfirmationModalCallbacks |
    ICategoriesTreeModalCallbacks |
    ICategoryCreateModalCallbacks |
    IDocumentMarkupModalCallbacks |
    IDocumentVersionsModalCallbacks |
    IGuestTeamModalCallbacks |
    IHistoryModalCallbacks |
    IRenameModalCallbacks |
    IResolveUploadModalCallbacks |
    ITenantCreateModalCallbacks
;

type ModalCallbacksMappingType = {
    [K in ModalNamesType]: {
        [id: string]: K extends 'confirmationModal' ? IConfirmationModalCallbacks :
            K extends 'categoriesTreeModal' ? ICategoriesTreeModalCallbacks :
                K extends 'categoryCreateModal' ? ICategoryCreateModalCallbacks :
                    K extends 'documentMarkupModal' ? IDocumentMarkupModalCallbacks :
                        K extends 'documentVersionsModal' ? IDocumentVersionsModalCallbacks :
                            K extends 'guestTeamModal' ? IGuestTeamModalCallbacks :
                                K extends 'historyModal' ? IHistoryModalCallbacks :
                                    K extends 'renameModal' ? IRenameModalCallbacks :
                                        K extends 'resolveUploadModal' ? IResolveUploadModalCallbacks :
                                            K extends 'tenantCreateModal' ? ITenantCreateModalCallbacks :
                                                null,
    }
}

type ModalInputType = {
    modal: ModalNamesType;
    id: string;
    callbacks?: ModalCallbacksType;
}

export interface IModalContext {
    modalCallbacks: ModalCallbacksMappingType;
    registerModalCallbacks: (payload: ModalInputType) => void;
    unregisterModalCallbacks: (payload: ModalInputType) => void;
}

export const ModalContext = createContext<IModalContext>({
    modalCallbacks: {
        confirmationModal: {},
        categoriesTreeModal: {},
        categoryCreateModal: {},
        documentMarkupModal: {},
        documentVersionsModal: {},
        guestTeamModal: {},
        historyModal: {},
        renameModal: {},
        resolveUploadModal: {},
        tenantCreateModal: {},
    },
    registerModalCallbacks: () => {},
    unregisterModalCallbacks: () => {}
});

interface ModalProviderProps {
    children: React.ReactNode;
}

const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
    const modalCallbacksRef = useRef<ModalCallbacksMappingType>({
        confirmationModal: {},
        categoriesTreeModal: {},
        categoryCreateModal: {},
        documentMarkupModal: {},
        documentVersionsModal: {},
        guestTeamModal: {},
        historyModal: {},
        renameModal: {},
        resolveUploadModal: {},
        tenantCreateModal: {},
    });

    const registerModalCallbacks = (payload: ModalInputType) => {
        const { modal, id, callbacks } = payload;
        if (!modalCallbacksRef.current[modal]) {
            modalCallbacksRef.current[modal] = {};
        }
        if (callbacks) {
            modalCallbacksRef.current[modal][id] = callbacks;
        }
    };

    const unregisterModalCallbacks = (payload: ModalInputType) => {
        const { modal, id } = payload;
        if (modalCallbacksRef.current[modal]) {
            delete modalCallbacksRef.current[modal][id];
        }
    };

    return (
        <ModalContext.Provider
            value={{
                modalCallbacks: modalCallbacksRef.current,
                registerModalCallbacks,
                unregisterModalCallbacks,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
