import { Button, Input, Modal, Form } from 'antd';
import {
    useEffect,
    useState
} from 'react';
import { closeModal } from 'slices/modalsSlice';
import { createTenant } from 'slices/tenantsActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

export interface ITenantCreateModalProps {};

export interface ITenantCreateModalCallbacks {
    onSubmit?: () => void;
    onClose?: () => void;
};

const TenantCreateModal = ({ id, zIndex, props, callbacks }: { id: string, zIndex: number, props: ITenantCreateModalProps, callbacks: ITenantCreateModalCallbacks }) => {
    const dispatch = useAppDispatch();
    const { onSubmit, onClose } = callbacks;
    const { loading, error } = useAppSelector((state) => state.tenants);
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('');

    useEffect(() => {
        if (isOpen) {
            setName('New Workspace');
        }
    }, [isOpen]);

    useEffect(() => {
        if (!id) return;
        setIsOpen(true);
    }, [id]);

    const handleClose = () => {
        onClose?.();
    };

    const handleSubmit = () => {
        dispatch(createTenant({ name }))
            .unwrap()
            .then(() => {
                onSubmit?.();
            }).catch(() => {});
    };

    const close = () => {
        dispatch(closeModal({ modal: 'tenantCreateModal', id }));
    };

    return (
        <Modal
            zIndex={zIndex}
            title={'Create Workspace'}
            open={isOpen}
            onCancel={handleClose}
            onOk={handleSubmit}
            footer={
                <>
                    <Button
                        color="primary"
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        loading={loading}
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Create
                    </Button>
                </>
            }
        >
            <Form.Item
                validateStatus={error ? 'error' : ''}
                help={error?.errors?.name?.[0]}
            >
                <Input
                    id="name"
                    placeholder="Workspace Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    required
                />
            </Form.Item>
        </Modal>
    );
};

export default TenantCreateModal;