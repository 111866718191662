import { RollbackOutlined } from '@ant-design/icons';
import { useModal } from 'hooks/useModal';
import { Document } from 'interfaces/documents';

export const Versions = (row: Document) => {
    const { openModal } = useModal();

    return {
        key: 'Versions',
        label: 'Versions',
        onClick: () => {
            openModal('documentVersionsModal', {
                document: row,
            });
        },
        icon: <RollbackOutlined />,
    };
}