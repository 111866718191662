import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import {
    Button,
    Card,
    Col,
    Row,
    Space,
    Checkbox,
    Divider,
} from "antd";
import type { CheckboxProps } from "antd";
import { useAppSelector } from "utilities/hooks";
import { useAxios } from "utilities/axios";
import type { SelectProps } from "antd";
import DebounceSelect from "./DebounceSelect";
import { User } from "interfaces/users";

export interface DebounceSelectProps<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
    fetchOptions: (search: string) => Promise<ValueType[]>;
    debounceTimeout?: number;
};

const CheckboxGroup = Checkbox.Group;

const plainOptions = [
    'files.view',
    'files.create',
    'files.edit',
    'files.manage',
    'files.project.view',
    'files.project.create',
    'files.project.edit',
    'files.project.manage',
];

interface UserValue {
    label: string;
    value: string;
}

export interface PermissionTemplate {
    id: string;
    name: string;
    permissions: string[] | null;
    users: User[] | null;
}

// NOTE: Page ONLY for internal use
const PermissionsPage = () => {
    const { accessToken } = useAppSelector((state) => state.auth);
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const axios = useAxios(accessToken, currentTenant);

    const [templates, setTemplates] = useState<PermissionTemplate[]>([]);
    const [templatePermissions, setTemplatePermissions] = useState<Record<string, string[]>>({});
    const [templateUsers, setTemplateUsers] = useState<Record<string, UserValue[]>>({});
    const [templateLoading, setTemplateLoading] = useState<Record<string, boolean>>({});

    const fetchData = async () => {
        const response = await axios.get('api/v1/permissions/templates');
        const data: PermissionTemplate[] = response.data.data;
        setTemplates(data);

        const initialPermissions: Record<string, string[]> = {};
        const initialUsers: Record<string, UserValue[]> = {};
        data.forEach((template) => {
            initialPermissions[template.id] = template.permissions ?? [];

            if (template.users && template.users.length > 0) {
                let users = template.users.map(user => ({ label: `${user.first_name} ${user.last_name}`, value: user.id }));
                initialUsers[template.id] = users;
            }
        });

        setTemplatePermissions(initialPermissions);
        setTemplateUsers(initialUsers);
    };

    useEffect(() => {
        fetchData().catch(console.error);
    }, []);

    const onChange = (templateId: string, list: string[]) => {
        setTemplatePermissions((prev) => ({
            ...prev,
            [templateId]: list,
        }));
    };

    const onCheckAllChange = (templateId: string, e: CheckboxProps["onChange"]) => {
        setTemplatePermissions((prev) => ({
            ...prev,
            // @ts-ignore
            [templateId]: e?.target?.checked ? plainOptions : [],
        }));
    };

    const handleSave = async (template: PermissionTemplate) => {
        const permissions = templatePermissions[template.id];
        const users = templateUsers[template.id];

        try {
            setTemplateLoading(prev => ({
                ...prev,
                [template.id]: true
            }));
            await axios.put(`api/v1/permissions/templates/${template.id}`, {
                name: template.name,
                permissions: permissions,
                users: users.map(user => user.value)
            });

            alert(`Template "${template.id}" updated successfully!`);
        } catch (error) {
            console.error("Error updating template:", error);
        }

        setTemplateLoading(prev => ({
            ...prev,
            [template.id]: false
        }));
    };

    async function fetchUserList(username: string): Promise<UserValue[]> {
        let result: UserValue[] = [];
        let params: { search?: string } = {};
        if (username) params.search = username;

        try {
            const response = await axios.get(`api/v1/users`, {
                params: params
            });

            response.data.data.forEach((user: User) => {
                result.push({
                    label: `${user.first_name} ${user.last_name}`,
                    value: user.id
                });
            });
        } catch (error) {
            console.error("Error fetching users:", error);
        }

        return result;
    }

    return <>
        <div>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Button type="primary">
                    + Create Permission Template
                </Button>
                <Space />
                <Row gutter={[16, 24]}>
                    {templates && templates.map(template => {
                        const checkedList = templatePermissions[template.id] || [];
                        const checkAll = checkedList.length === plainOptions.length;
                        const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;

                        return <Col span={8} key={template.id}>
                            <Card
                                title={template.name}
                                bordered={true}
                                extra={
                                    <Button onClick={() => handleSave(template)}>
                                        {templateLoading[template.id] ? 'Saving...' : 'Save'}
                                    </Button>
                                }
                            >
                                <Checkbox
                                    indeterminate={indeterminate}
                                    // @ts-ignore
                                    onChange={(e) => onCheckAllChange(template.id, e)}
                                    checked={checkAll}
                                >
                                    Check all
                                </Checkbox>
                                <Divider />
                                <CheckboxGroup
                                    options={plainOptions}
                                    value={checkedList}
                                    onChange={(list) => onChange(template.id, list as string[])}
                                />
                                <Divider />
                                <DebounceSelect
                                    mode="multiple"
                                    value={templateUsers[template.id]}
                                    placeholder="Select users"
                                    fetchOptions={fetchUserList}
                                    onChange={(newValue) => {
                                        setTemplateUsers((prev) => ({
                                            ...prev,
                                            [template.id]: newValue as UserValue[],
                                        }));
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Card>
                        </Col>
                    })}
                </Row>
            </Space>
        </div>
    </>;
};

export default PermissionsPage;