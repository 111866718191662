import { ArrowRightOutlined } from '@ant-design/icons';
import { Category, Document } from 'interfaces/documents';
import { useModal } from 'hooks/useModal';
import { moveDocument } from 'slices/documentsActions';
import { useAppDispatch } from 'utilities/hooks';
import { moveCategory } from 'slices/categoriesActions';

export const Move = (
    row: Category | Document,
    fetchData: () => void
) => {
    const dispatch = useAppDispatch();
    const { openModal } = useModal();

    const moveItem = (destination: Category | null) => {
        if ((row as Category).type === 'category') {
            dispatch(moveCategory({
                id: (row as Category).uuid,
                parent_id: destination?.id || null,
            }));
        } else {
            dispatch(moveDocument({
                id: (row as Document).id,
                category_id: destination?.id || null,
            }));
        }

        fetchData();
    };

    return {
        key: 'Move',
        label: 'Move',
        onClick: () => {
            openModal('categoriesTreeModal', {
                title: 'Move to...',
                submitLabel: 'Move',
                cancelLabel: 'Cancel',
            }, {
                onSubmit: (value: Category | null) => {
                    moveItem(value);
                }
            });
        },
        icon: <ArrowRightOutlined />,
    };
}