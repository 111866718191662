import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { useAppDispatch } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';
import { DateTime } from 'luxon';
import {
    COLOR_ITEM,
    FORMAT_DATE_TIME,
    ScheduleItemType,
    FORMAT_DATE_FOR_SAVE,
    FieldStartDate,
    FieldEndDate,
    FieldPhase,
    FieldDuration,
} from 'smx-components';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import useHandleDateRange2 from '../../../../hooks/useHandleDateRange2';
import { NewItemType } from '../../../../types';
import FieldProjectForAddItem from './fieldProjectForAddItem';
import FieldScheduleItemForAddItem from './fieldScheduleItemForAddItem';

const ButtonAddScheduleItem = () => {
    const dispatch = useAppDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm<NewItemType>();
    const refListProject = useRef<React.FC<any>>(null);

    const values = Form.useWatch([], form);

    useHandleDateRange2(form);

    useEffect(() => {
        form.setFieldsValue({
            startDate: dayjs(DateTime.now().toFormat(FORMAT_DATE_TIME)) as any,
            endDate: dayjs(DateTime.now().plus({ days: 3 }).toFormat(FORMAT_DATE_TIME)) as any,
            duration: 4,
        });
    }, [isModalOpen]);

    const onClickAdd = () => {
        setIsModalOpen(true);
    };

    const addNewItem = (values: NewItemType) => {
        const newItem = {
            color: COLOR_ITEM,
            start_datetime: DateTime.fromISO((values?.startDate as any).format(FORMAT_DATE_FOR_SAVE)).toFormat(
                FORMAT_DATE_TIME,
            ),
            end_datetime: DateTime.fromISO((values?.endDate as any).format(FORMAT_DATE_FOR_SAVE)).toFormat(
                FORMAT_DATE_TIME,
            ),
            project_id: values.projectId,
            phase_id: values.phaseId,
            parent_id: values.parentId,
            completion_percentage: 0,
            assignee: '',
            role_item: 'workItem',
        } as ScheduleItemType;

        dispatch(setterSchedule({ triggerForNewItem: newItem }));
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            addNewItem(values);
            setIsModalOpen(false);
        } catch (errorInfo) {
            console.log('Validation error:', errorInfo);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button type={'primary'} onClick={onClickAdd} icon={<PlusOutlined />}>
                Add Item
            </Button>
            <Modal
                title="Add new schedule item"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                zIndex={2000}
                width={300}
            >
                <Form
                    name="newScheduleItemFrom"
                    layout="vertical"
                    form={form}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                >
                    <FieldProjectForAddItem refListProject={refListProject} />
                    <FieldPhase
                        name={'phaseId'}
                        parentRef={refListProject as any}
                        required
                        disabled={!values?.['projectId']}
                    />
                    <FieldScheduleItemForAddItem />

                    <FieldStartDate required />
                    <FieldEndDate required />
                    <FieldDuration name="duration" required />
                    <Form.Item<NewItemType> name="businessDays" valuePropName="checked">
                        <Checkbox>Weekdays only</Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ButtonAddScheduleItem;
