import React from 'react';
import { Form } from 'antd';
import { useAppSelector } from '../../../../utilities/hooks';
import { FieldScheduleItem } from 'smx-components';
import { NewItemType } from '../../../../types';

const FieldScheduleItemForAddItem = () => {
    const globalFilterProject = useAppSelector((state) => state.app.globalFilterProject);

    const form = Form.useFormInstance<NewItemType>();

    const values = Form.useWatch([], form);

    const filterFlexible = globalFilterProject?.length
        ? `[{"or":[{"project_id":"${globalFilterProject.join('|')}"}]}]`
        : undefined;

    return (
        <FieldScheduleItem
            name={'parentId'}
            localFilters={{
                role_item: 'workItem',
                phase_id: values?.['phaseId']?.toString(),
            }}
            filterFlexible={filterFlexible}
            disabled={!values?.['phaseId']}
        />
    );
};

export default FieldScheduleItemForAddItem;
