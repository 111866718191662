import {
    Description,
    Folder,
    MoreVert,
    Refresh
} from '@mui/icons-material';
import {
    Box,
    Button,
    Chip,
    Fade,
    Grid,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import {
    GridCallbackDetails,
    GridCellParams,
    GridColDef,
    GridRowId,
    GridRowModel,
    GridRowParams,
    GridRowSelectionModel,
    MuiEvent
} from '@mui/x-data-grid';
import CustomBreadCrumbs from 'components/CustomBreadCrumbs';
import DefaultDataGrid from 'components/DefaultDataGrid';
import {
    Categories,
    Category,
    Document,
    Documents,
} from 'interfaces/documents';
import {
    useCallback,
    useEffect,
    useState
} from 'react';
import Moment from 'react-moment';
import {
    useLocation,
    useNavigate
} from 'react-router-dom';
import {
    setDocument
} from 'slices/documentSlice';
import {
    fetchSharedDocuments
} from 'slices/documentsActions';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';
import { useModal } from 'hooks/useModal';

const DocumentsSharedWithMePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { accessToken } = useAppSelector((state) => state.auth);
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { loading, list, breadcrumbs } = useAppSelector((state) => state.documents);
    const [isRowsSelected, setIsRowsSelected] = useState(false);
    const { openModal: openDocumentMarkupModal } = useModal();
    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);

    const _currentTenant = useCallback(() => {
        return currentTenant;
    }, [currentTenant]);

    useEffect(() => {
        dispatch(fetchSharedDocuments(null));
    }, [location, accessToken, _currentTenant]);

    const handleBreadcrumbClick = () => {
        dispatch(fetchSharedDocuments(null));
    };

    const handleCategory = (category: Category) => {
        dispatch(fetchSharedDocuments(category.id));
    };

    const handleDocumentOpen = (document: Document) => {
        dispatch(setDocument(document));
        openDocumentMarkupModal('documentMarkupModal');
    };

    const columns: GridColDef[] = [
        {
            flex: 1,
            field: 'name',
            headerName: 'Name',
            renderCell: (params) => {
                if (params.row.type === 'category') {
                    return <><Folder /><Typography sx={{pl: 2}}>{params.row.name}</Typography></>
                } else {
                    return <><Description /><Typography sx={{pl: 2}}>{params.row.name}</Typography></>
                }
            }
        },
        {
            field: 'size_formatted',
            headerName: 'Size',
            width: 150
        },
        {
            field: 'deleted_at',
            headerName: 'Deleted',
            width: 200,
            renderCell: (params) => <Moment date={params.row.deleted_at} format="lll" />
        },
    ];

    const handleSelectRows = (selectedRows: GridRowSelectionModel) => {
        let selectedDocuments: Documents = [];
        let selectedCategories: Categories = [];
        selectedRows.map((rowId: GridRowId) => {
            list.map((item: any) => {
                if (item.type === 'category') {
                    if (item.uuid === rowId) {
                        selectedCategories.push(item);
                    }
                }
                if (item.id === rowId) {
                    selectedDocuments.push(item);
                }
            });
        });

        setSelectedRows(selectedRows);
        setIsRowsSelected(selectedRows.length > 0);
    };

    const handleUnselectAll = () => {
        handleSelectRows([]);
    };

    /**
     * Refresh
     */
    const handleRefresh = () => {
        dispatch(fetchSharedDocuments(null));
    };

    return <>
        <Box id="notification-bar"></Box>
        <Grid container sx={{
            position: 'sticky',
            m: 0,
            p: 0,
            top: -1,
            bgcolor: 'white',
            zIndex: 'appBar',
        }}>
            <Grid item xs={12} sx={{py: 3}}>
                <CustomBreadCrumbs
                    title={'Shared with me'}
                    loading={loading}
                    breadcrumbs={breadcrumbs}
                    onRootClick={() => handleBreadcrumbClick()}
                />
            </Grid>
            <Grid item xs={12} sx={{pb: 3}}>
                <Fade in={isRowsSelected} hidden={!isRowsSelected} appear={false}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Chip
                            label={`${selectedRows.length} items selected`}
                            color="primary"
                            onClick={handleUnselectAll}
                            onDelete={handleUnselectAll}
                        />
                        <IconButton
                            color="primary"
                            onClick={() => console.log('More')}
                        >
                            <MoreVert />
                        </IconButton>
                    </Stack>
                </Fade>
                <Fade in={!isRowsSelected} hidden={isRowsSelected} appear={false}>
                    <Stack direction="row" spacing={2} alignItems="center" sx={{minHeight: '40px'}}>
                        <Button
                            color="primary"
                            disableElevation
                            onClick={handleRefresh}
                            startIcon={<Refresh />}
                        >
                            Refresh
                        </Button>
                    </Stack>
                </Fade>
            </Grid>
        </Grid>
        <Grid container sx={{m: 0}}>
            <DefaultDataGrid
                rows={list}
                columns={columns}
                loading={loading}
                disableColumnMenu
                disableRowSelectionOnClick
                hideFooter
                checkboxSelection
                rowSelectionModel={selectedRows}
                getRowId={(row: GridRowModel) => {
                    if (row.type === 'category') {
                        return row.uuid;
                    } else {
                        return row.id;
                    }
                }}
                onRowClick={(params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => {}}
                onCellClick={(params: GridCellParams, event: MuiEvent, details: GridCallbackDetails) => {
                    if (params.field !== 'name') return;
                    if (params.row.type === 'category') {
                        handleCategory(params.row);
                    } else {
                        handleDocumentOpen(params.row);
                    }
                }}
                onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {
                    handleSelectRows(rowSelectionModel);
                }}
            />
        </Grid>
    </>;
};

export default DocumentsSharedWithMePage;