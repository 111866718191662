import { HistoryOutlined } from '@ant-design/icons';
import { Category, Document } from 'interfaces/documents';
import { useModal } from 'hooks/useModal';

export const History = (
    row: Category | Document,
) => {
    const { openModal } = useModal();

    return {
        key: 'History',
        label: 'History',
        onClick: () => {
            openModal('historyModal', {
                id: row.type === 'document' ? (row as Document).id : (row as Category).uuid,
            });
        },
        icon: <HistoryOutlined />,
    };
}