import React from 'react';
import { Menu } from 'antd';
import styled from 'styled-components';
import {
    DashboardCustomize,
    Domain,
    LineStyle,
    PermMediaRounded,
    SettingsRounded,
    SupervisorAccount,
} from '@mui/icons-material';
import { useSitemaxUIController } from 'context';
import { useLocation, useNavigate, useParams, NavLink } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { useTheme } from '@mui/material/styles';
import WBox from 'containers/WBox';
import SidenavRoot from './SidenavRoot';
import { useAllowed } from 'utilities/hooks';

const LOGO_URL = 'https://sitemaxsystems.com/wp-content/uploads/2023/12/SMX-Icon.png';

const StyledMenu = styled(Menu)`
    .ant-menu-item-divider {
        margin: 10px 15px;
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
        text-align: center;
        text-overflow: unset;
        color: rgba(0, 0, 0, 0.70);
        height: 42px !important;
        line-height: 42px !important;

        .ant-menu-item-icon {
            font-size: 20px;
            min-width: 20px;
        }

        .ant-menu-title-content {
            display: none;
        }

        .ant-menu-submenu-arrow {
            display: none;
        }
    }
`;

type MenuItem = {
    key: string;
    label: string;
    icon?: React.ReactNode;
    children?: MenuItem[];
    isDivider?: boolean;
    route?: string;
};

const menuItems: MenuItem[] = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        icon: <DashboardCustomize />,
        route: '/dashboards',
    },
    {
        key: 'divider1',
        label: '',
        isDivider: true,
    },
    {
        key: 'projects',
        label: 'Projects',
        icon: <Domain />,
        route: '/projects',
    },
    {
        key: 'users',
        label: 'Users',
        icon: <SupervisorAccount />,
        route: '/users',
    },
    {
        key: 'documents',
        label: 'Documents',
        icon: <PermMediaRounded />,
        route: '/documents',
        children: [
            {
                key: 'shared',
                label: 'Shared with me',
                // icon: <ShareAltOutlined />,
                route: '/documents/shared',
            },
            {
                key: 'deleted',
                label: 'Deleted files',
                // icon: <DeleteOutlined />,
                route: '/documents/trash',
            },
        ],
    },
    {
        key: 'schedule',
        label: 'Schedule',
        icon: <LineStyle />,
        route: '/schedule',
    },
    {
        key: 'divider2',
        label: '',
        isDivider: true,
    },
    {
        key: 'settings',
        label: 'Settings',
        icon: <SettingsRounded />,
        route: '/settings',
    },
];

const getProjectMenuItems = (projectId: string): MenuItem[] => [
    {
        key: 'project-dashboard',
        label: 'Project Dashboard',
        icon: <DashboardCustomize />,
        route: `/projects/${projectId}/dashboards`,
    },
    {
        key: 'divider1',
        label: '',
        isDivider: true,
    },
    {
        key: 'project-settings',
        label: 'Project Settings',
        icon: <SettingsRounded />,
        route: `/projects/${projectId}/settings`,
    }
];

const convertMenuItems = (items: MenuItem[], navigate: (path: string) => void): MenuProps['items'] => {
    return items.map((item) => {
        if (item.isDivider) {
            return {
                key: item.key,
                type: 'divider',
            };
        }

        const menuItem: any = {
            key: item.key,
            label: item.label,
            icon: item.icon,
            popupOffset: [1, 0],
            children: [],
            onTitleClick: () => {
                if (item.route) {
                    navigate(item.route);
                }
            }
        };

        menuItem.children = [{
            key: `${item.key}-header`,
            label: <div className="submenu-header" style={{ color: 'black', fontWeight: '600' }}>{item.label}</div>,
            disabled: false,
        }];

        if (item.children && item.children?.length > 0) {
            menuItem.children = [
                ...menuItem.children,
                ...item.children.map(child => ({
                    key: child.key,
                    label: child.label,
                    icon: child.icon,
                }))
            ];
        }

        return menuItem;
    });
};

const findMenuItemByKey = (items: MenuItem[], targetKey: string): MenuItem | undefined => {
    for (const item of items) {
        if (item.key === targetKey) return item;
        if (item.children) {
            const found = findMenuItemByKey(item.children, targetKey);
            if (found) return found;
        }
    }
    return undefined;
};

const SidebarMenu: React.FC = () => {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [controller, dispatchUI] = useSitemaxUIController();
    const { miniSidenav, transparentSidenav } = controller;

    const isAllowedViewDocuments = useAllowed(['files.edit', 'files.manage']);

    const isProjectRoute = location.pathname.includes('/projects/') && projectId;
    const currentMenuItems = isProjectRoute ? getProjectMenuItems(projectId) : menuItems;

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        const menuItem = findMenuItemByKey(menuItems, e.key);
        if (menuItem?.route) {
            navigate(menuItem.route);
        }
    };

    const getCurrentSelectedKey = () => {
        const currentPath = location.pathname;

        if (isProjectRoute) {
            const menuItem = getProjectMenuItems(projectId).find(item =>
                item.route === currentPath ||
                item.children?.some(child => child.route === currentPath)
            );
            return menuItem ? [menuItem.key] : ['project-dashboard'];
        }

        const menuItem = menuItems.find(item =>
            item.route === currentPath ||
            item.children?.some(child => child.route === currentPath)
        );

        return menuItem ? [menuItem.key] : ['dashboard'];
    };

    return (
        <SidenavRoot
            theme={theme}
            variant="permanent"
            ownerState={{ transparentSidenav, miniSidenav }}
        >
            <WBox
                component={NavLink}
                to="/"
                display="flex"
                alignItems="center"
                style={{
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    justifyContent: 'center',
                }}
            >
                <WBox
                    component="img"
                    src={LOGO_URL}
                    alt="Logo"
                    width="2rem"
                />
            </WBox>
            <StyledMenu
                mode="vertical"
                defaultSelectedKeys={['dashboard']}
                selectedKeys={getCurrentSelectedKey()}
                items={convertMenuItems(currentMenuItems, navigate)}
                onClick={handleMenuClick}
                onSelect={item => {
                    const menuItem = findMenuItemByKey(currentMenuItems, item.key.replace('-header', ''));
                    if (menuItem?.route) {
                        navigate(menuItem.route);
                    }
                }}
                triggerSubMenuAction="hover"
            />
        </SidenavRoot>
    );
};

export default SidebarMenu;