import { Box, Drawer, Typography } from '@mui/material';
import TenantsList from 'components/TenantsList';
import { Tenant } from 'interfaces/tenants';
import { useState } from 'react';

const DrawerTenantList = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <Drawer anchor="left" elevation={0} open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <Box sx={{ width: 250 }}>
                <Box sx={{ px: 2, pt: 2, pb: 1 }}>
                    <Typography variant="overline">Workspaces</Typography>
                </Box>
                <TenantsList onClick={(tenant: Tenant) => setIsDrawerOpen(false)} />
            </Box>
        </Drawer>
    );
};

export default DrawerTenantList;
