import { Avatar, Box, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { NavigationItem } from 'interfaces/navigation';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from 'slices/authActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const AvatarIconBar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { loading: loadingAccount, user } = useAppSelector((state) => state.account);
    const { settingsMenu } = useAppSelector((state) => state.navigationItems);

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleSingOut = async () => {
        dispatch(logout())
            .unwrap()
            .then(() => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleSettings = (event: React.MouseEvent<HTMLElement>, item: NavigationItem) => {
        setAnchorElUser(null);
        navigate(item.url);
    };

    return (
        <>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar>{user?.initials}</Avatar>
            </IconButton>
            <Menu
                sx={{ mt: 5 }}
                elevation={1}
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <Box sx={{ width: 240 }}>
                    {settingsMenu.map((item) => (
                        <MenuItem key={item.url} onClick={(e) => handleSettings(e, item)}>
                            <Typography textAlign="center">{item.title}</Typography>
                        </MenuItem>
                    ))}
                    <Divider />
                    <MenuItem key={'singout'} onClick={handleSingOut}>
                        <Typography textAlign="center">{'Sign Out'}</Typography>
                    </MenuItem>
                </Box>
            </Menu>
        </>
    );
};

export default AvatarIconBar;
