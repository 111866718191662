import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../interfaces/users';
import { fetchAccount, fetchPermissions, updateAccount, updateEmail, updatePassword, updatePhone, verifyEmail, verifyPhone } from './accountActions';
import { login, loginOTACode, logout } from './authActions';


interface AccountState {
    loading: boolean;
    error: any | null;
    success: any | null;
    user: User | null;
    roles: string[] | null;
    permissions: string[] | null;
};

export const initialState: AccountState = {
    loading: false,
    success: false,
    error: null,
    user: null,
    roles: [],
    permissions: [],
};

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
            state.user = action.payload.data.user;
        })
        builder.addCase(login.rejected, (state, action: PayloadAction<any>) => {
            state.user = null;
        })
        builder.addCase(loginOTACode.fulfilled, (state, action: PayloadAction<any>) => {
            state.user = action.payload.data.user;
        })
        builder.addCase(loginOTACode.rejected, (state, action: PayloadAction<any>) => {
            state.user = null;
        })

        builder.addCase(logout.fulfilled, (state, action: PayloadAction<any>) => {
            state.user = null;
        })
        builder.addCase(logout.rejected, (state, action: PayloadAction<any>) => {
            state.user = null;
        })

        builder.addCase(fetchAccount.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchAccount.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            state.user = action.payload.data;
            state.permissions = action.payload.data.permissions;
        })
        builder.addCase(fetchAccount.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.user = null;
            state.error = action.payload.data;
        })
        builder.addCase(updateAccount.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(updateAccount.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
            state.user = action.payload.data;
        })
        builder.addCase(updateAccount.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(updateEmail.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(updateEmail.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(updateEmail.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(verifyEmail.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(verifyEmail.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
            state.user = action.payload.data;
        })
        builder.addCase(verifyEmail.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(updatePhone.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(updatePhone.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(updatePhone.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(verifyPhone.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(verifyPhone.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
            state.user = action.payload.data;
        })
        builder.addCase(verifyPhone.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(updatePassword.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(updatePassword.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
            state.user = action.payload.data;
        })
        builder.addCase(updatePassword.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(fetchPermissions.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchPermissions.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
            if (!action.payload) return;
            if (!action.payload.data) return;

            state.roles = action.payload.data.roles;
            state.permissions = action.payload.data.permissions;
        })
        builder.addCase(fetchPermissions.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
    },
});

export const { reset } = accountSlice.actions;
export default accountSlice.reducer;