import { DeleteOutlined } from '@ant-design/icons';
import { Category, Document } from 'interfaces/documents';
import { useModal } from 'hooks/useModal';
import { softDeleteCategory } from 'slices/categoriesActions';
import { softDeleteDocument } from 'slices/documentsActions';
import { useAppDispatch } from 'utilities/hooks';

export const Delete = (
    row: Category | Document,
    fetchData: () => void
) => {
    const dispatch = useAppDispatch();
    const { openModal } = useModal();

    const deleteItem = () => {
        if (!row) return;

        if ((row as Category).type === 'category') {
            dispatch(softDeleteCategory((row as Category).id));
        } else {
            dispatch(softDeleteDocument((row as Document).id));
        }

        fetchData();
    };

    return {
        key: 'Delete',
        label: 'Delete',
        onClick: () => {
            openModal('confirmationModal', {
                text: 'Are you sure want to delete item?',
            }, {
                onConfirm: () => {
                    deleteItem();
                }
            });
        },
        icon: <DeleteOutlined />,
    };
}