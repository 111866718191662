import { Input, Modal } from 'antd';
import { Category, Document } from 'interfaces/documents';
import {
    useEffect,
    useState
} from 'react';
import { closeModal } from 'slices/modalsSlice';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

export interface IRenameModalProps {
    entity: Category | Document;
};

export interface IRenameModalCallbacks {
    onSubmit?: (name: string) => void;
    onClose?: () => void;
};

const RenameModal = ({ id, zIndex, props, callbacks }: { id: string, zIndex: number, props: IRenameModalProps, callbacks: IRenameModalCallbacks }) => {
    const dispatch = useAppDispatch();
    const { entity } = props;
    const { onSubmit, onClose } = callbacks;
    const { error: documentError } = useAppSelector((state) => state.document);
    const { error: categoryError } = useAppSelector((state) => state.category);
    const error = entity.type === 'category' ? categoryError : documentError;
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState(entity?.name);

    useEffect(() => {
        if (id) {
            setIsOpen(true);
        }
    }, [id]);

    const close = () => {
        console.log('close');
        dispatch(closeModal({ modal: 'renameModal', id }));
    };

    const handleOnOk = () => {
        onSubmit?.(name);
        close();
    };

    const handleOnCancel = () => {
        onClose?.();
        close();
    };

    return <Modal
        zIndex={zIndex}
        title={entity?.type === 'category' ? 'Rename folder' : 'Rename document'}
        open={isOpen}
        onCancel={handleOnCancel}
        onOk={handleOnOk}
        okText={'Rename'}
    >
        <Input
            id="name"
            placeholder={error?.errors?.name?.[0] || 'Name'}
            status={error?.errors?.name ? 'error' : undefined}
            defaultValue={entity?.name}
            onChange={(e) => {
                setName(e.target.value);
            }}
            autoFocus
            required
        />
    </Modal>;
};

export default RenameModal;