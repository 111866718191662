import { TaskBoard } from 'smx-components';
import { useAppDispatch, useAppSelector } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';

const WrapperTaskBoard = () => {
    const dispatch = useAppDispatch();

    const globalFilterProject = useAppSelector((state) => state.app.globalFilterProject);

    const onFinishLoadData = () => {
        dispatch(setterSchedule({ isGantLoaded: true }));
    };

    return (
        <TaskBoard
            paddingBottom={25}
            marginTop={25}
            filterProject={globalFilterProject}
            onFinishLoadData={onFinishLoadData}
        />
    );
};

export default WrapperTaskBoard;
