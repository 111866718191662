import { Button, Dropdown, Space } from "antd";
import { ArrowRightOutlined, CopyOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { ItemType } from "antd/es/menu/interface";
import { useRef } from "react";

import { BaseModalRef } from "modals/BaseModal";
import { softDeleteCategory } from "slices/categoriesActions";
import { setExcluded } from "slices/categoriesSlice";
import { softDeleteDocument } from "slices/documentsActions";
import { useAppDispatch } from "utilities/hooks";
import { useModal } from "hooks/useModal";
import { Category, Document } from "interfaces/documents";

const ActionDropdown = ({
    fetchData,
    selected,
}: {
    fetchData: () => void,
    selected: (Category | Document)[],
}) => {
    const dispatch = useAppDispatch();
    const copyBulkModal = useRef<BaseModalRef>(null);
    const moveBulkModal = useRef<BaseModalRef>(null);
    const { openModal } = useModal();

    const actions: ItemType[] = [
        {
            key: 'Delete',
            label: 'Delete',
            onClick: () => {
                openModal('confirmationModal', {
                    text: 'Are you sure want to delete selected items?',
                }, {
                    onConfirm: () => {
                        bulkDelete(true);
                    }
                });
            },
            icon: <DeleteOutlined />,
            disabled: !selected.length,
        },
        {
            key: 'Copy',
            label: 'Copy',
            onClick: () => {
                copyBulkModal.current?.open();
            },
            icon: <CopyOutlined />,
            disabled: !selected.length || !!selected.filter((item) => item.type === 'category').length,
        },
        {
            key: 'Move',
            label: 'Move',
            onClick: () => openBulkMoveModal(),
            icon: <ArrowRightOutlined />,
            disabled: !selected.length,
        },
    ];

    const bulkDelete = (hasConfirmed: Boolean) => {
        if (!hasConfirmed) return;

        selected.forEach((item: Category | Document) => {
            if (item.type === 'category') {
                dispatch(softDeleteCategory(item.id))
                    .unwrap()
                    .then(() => {
                        fetchData();
                    })
                    .catch();
            } else {
                dispatch(softDeleteDocument(item.id))
                    .unwrap()
                    .then(() => {
                        fetchData();
                    })
                    .catch();
            }
        });
    };

    const openBulkMoveModal = () => {
        const categories = selected.filter((item: Category | Document) => item.type === 'category');
        dispatch(setExcluded(categories as Category[]));
        moveBulkModal.current?.open();
    };

    return (
        <>
            <Dropdown menu={{ items: actions }}>
                <Button>
                    <Space>
                        Actions
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
        </>
    )
}

export default ActionDropdown;
