import {
    useEffect,
    useState
} from 'react';
import {
    reset
} from 'slices/documentSlice';
import {
    fetchDocumentVersions,
} from 'slices/documentsActions';
import { closeModal } from 'slices/modalsSlice';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';
import { Button, Modal, Table, Tag } from 'antd';
import TableColumns from './TableColumns';
import { Document } from 'interfaces/documents';
import { FileTextOutlined } from '@ant-design/icons';

export interface IDocumentVersionsModalProps {
    document: Document;
};

export interface IDocumentVersionsModalCallbacks {
    onCancel?: (value: Boolean) => void;
    onClose?: () => void;
};

const DocumentVersionsModal = ({ id, zIndex, props, callbacks }: { id: string, zIndex: number, props: IDocumentVersionsModalProps, callbacks: IDocumentVersionsModalCallbacks }) => {
    const dispatch = useAppDispatch();
    const { document } = props;
    const { onCancel, onClose } = callbacks;

    const [isOpen, setIsOpen] = useState(false);
    const { loading, versions } = useAppSelector((state) => state.document);

    useEffect(() => {
        if (!id || !document) return;

        setIsOpen(true);
        dispatch(fetchDocumentVersions(document.id));
    }, [id, document]);

    const close = () => {
        dispatch(closeModal({ modal: 'documentVersionsModal', id }));
    };

    const handleClose = () => {
        dispatch(reset());
        onClose?.();
        close();
    };

    const handleCancel = () => {
        onCancel?.(false);
        close();
    };

    return <Modal
        zIndex={zIndex}
        title={
            <>
                Versions of{" "}
                <Tag color="blue" style={{ marginLeft: 8 }}>
                    <FileTextOutlined style={{ marginRight: 4 }} />
                    {document?.name}
                </Tag>
            </>
        }
        open={isOpen}
        onCancel={handleClose}
        footer={
            <>
                <Button
                    color="primary"
                    onClick={() => handleCancel()}
                >
                    Close
                </Button>
            </>
        }
        width={800}
    >
        <Table
            dataSource={versions}
            columns={TableColumns({ document })}
            loading={loading}
            pagination={false}
        />
    </Modal>;
};

export default DocumentVersionsModal;
