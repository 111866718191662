import { useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ModalContext } from "../../providers/modalProvider";
import { ModalNamesType } from "slices/modalsSlice";
import CategoriesTreeModal from "modals/CategoriesTreeModal";
import ConfirmationModal from "modals/ConfirmationModal";
import DocumentVersionsModal from "modals/DocumentVersionsModal";
import GuestTeamModal from "modals/GuestTeamModal";
import RenameModal from "modals/RenameModal";
import TenantCreateModal from "modals/TenantCreateModal";
import ResolveUploadModal from "modals/ResolveUploadModal";
import HistoryModal from "modals/HistoryModal";
import CategoryCreateModal from "modals/CategoryCreateModal";
import DocumentMarkupModal from "modals/DocumentMarkupModal";

const ModalEntryPoint = () => {
    const { modalCallbacks } = useContext(ModalContext);
    const modals = useSelector((state: RootState) => state.modals);
    const modalKeys: ModalNamesType[] = Object.keys(modals) as ModalNamesType[];
    const modalComponents = {
        'confirmationModal': ConfirmationModal,
        'categoriesTreeModal': CategoriesTreeModal,
        'categoryCreateModal': CategoryCreateModal,
        'documentMarkupModal': DocumentMarkupModal,
        'documentVersionsModal': DocumentVersionsModal,
        'guestTeamModal': GuestTeamModal,
        'historyModal': HistoryModal,
        'renameModal': RenameModal,
        'resolveUploadModal': ResolveUploadModal,
        'tenantCreateModal': TenantCreateModal,
    };
    const zIndexes: Record<string, number> = {};

    const getZIndex = (id: string) => {
        if (!zIndexes[id]) {
            const maxZIndex = Object.values(zIndexes).length
                ? Math.max(...Object.values(zIndexes))
                : 1000;
            zIndexes[id] = maxZIndex + 1000;
        }
        return zIndexes[id];
    }

    return (
        <>
            {modalKeys.map((modalKey: ModalNamesType) => {
                return Object.keys(modals[modalKey]).map((id) => {
                    const ModalComponent = modalComponents[modalKey] as React.FC<any>;
                    return (
                        <ModalComponent
                            key={id}
                            id={id}
                            props={modals[modalKey][id]}
                            callbacks={modalCallbacks[modalKey][id]}
                            zIndex={getZIndex(id)}
                        />
                    );
                });
            })}
        </>
    );
}

export default ModalEntryPoint;
