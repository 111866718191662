import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Flex, Button, Space, Typography, Modal, Spin } from 'antd';
import { getApiUrl, getAccessToken, useAxios } from 'utilities/axios';
import { useAppSelector } from 'utilities/hooks';
import { capitalize } from 'lodash';

const providers = [
    'permission-templates',
    'projects',
    'users',
    'companies',
    'cost-code-groups',
    'cost-codes',
    'classifications',
    'divisions',
    'phases',
];

const MigrationPage = () => {
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const accessToken = getAccessToken();
    const axios = useAxios(accessToken, currentTenant);
    const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
    const [results, setResults] = useState<{ [key: string]: any }>({});
    const [modalData, setModalData] = useState<{ provider: string | null; results: any }>({
        provider: null,
        results: null,
    });

    const handleAction = async (provider: string, action: 'migration' | 'sync') => {
        setLoading((prev) => ({ ...prev, [provider]: true }));

        try {
            const response = await axios.post(`api/v1/${action}/${provider}`);
            setResults((prev) => ({ ...prev, [provider]: response.data.results }));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading((prev) => ({ ...prev, [provider]: false }));
        }
    };

    const handleShowResult = (provider: string) => {
        setModalData({ provider, results: results[provider] });
    };

    return (
        <>
            <Flex vertical gap="small" style={{ width: '30%' }}>
                {providers &&
                    providers.map((provider, key) => (
                        <div key={key}>
                            <Typography.Title level={5}>{capitalize(provider)}</Typography.Title>
                            <Space>
                                <Button
                                    type="primary"
                                    onClick={() => handleAction(provider, 'migration')}
                                    loading={loading[provider]}
                                >
                                    Migration
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() => handleAction(provider, 'sync')}
                                    loading={loading[provider]}
                                >
                                    Sync
                                </Button>
                                {results[provider] && (
                                    <Button type="default" onClick={() => handleShowResult(provider)}>
                                        Show Result
                                    </Button>
                                )}
                            </Space>
                        </div>
                    ))}
            </Flex>

            <Modal
                title={`Results for ${modalData.provider ? capitalize(modalData.provider) : ''}`}
                open={!!modalData.provider}
                onCancel={() => setModalData({ provider: null, results: null })}
                footer={null}
            >
                {modalData.results ? (
                    <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(modalData.results, null, 2)}</pre>
                ) : (
                    <Spin />
                )}
            </Modal>
        </>
    );
};

export default MigrationPage;
