import {
    Grid,
} from "@mui/material";
import CustomBreadCrumbs from "components/CustomBreadCrumbs";
import { useDocumentsPage } from "hooks/useDocumentsPage";
import DocumentsTable from "../components/DocumentsTable";

const DefaultDocumentsPage = () => {
    const {
        currentTeam,
        breadcrumbs,
        loading,
        navigateTo,
    } = useDocumentsPage();

    return (
        <>
            <Grid item xs={8} sx={{ pb: 2 }}>
                <CustomBreadCrumbs
                    title={currentTeam ? currentTeam.name : "Documents"}
                    loading={loading}
                    breadcrumbs={breadcrumbs}
                    onRootClick={() => {
                        navigateTo();
                    }}
                    onClick={(breadcrumb) => {
                        navigateTo(breadcrumb.id);
                    }}
                />
            </Grid>

            <DocumentsTable hasFileTree={true} />
        </>
    );
};

export default DefaultDocumentsPage;
