import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import {
    composeMiddlewares,
    buildRequestMiddleware,
    authMiddleware,
    settingsMiddleware,
    fetchMiddleware,
    remoteSettingsMiddleware,
} from 'smx-components';

const DEFAULT_TOKEN = '';

const createEnvironment = (token: string) => {
    const middlewareChain = composeMiddlewares([
        buildRequestMiddleware({
            graphqlUrl: `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}`,
            graphqlPathApi: 'api/v1',
        }),
        authMiddleware(token),
        settingsMiddleware,
        remoteSettingsMiddleware,
        fetchMiddleware,
    ]);

    return new Environment({
        network: Network.create(middlewareChain),
        store: new Store(new RecordSource()),
    });
};

const dynamicEnvironment = {
    token: DEFAULT_TOKEN,
    environment: createEnvironment(DEFAULT_TOKEN),
};

export const getEnvironment = (token: string): Environment => {
    if (token && dynamicEnvironment.token === token) {
        return dynamicEnvironment.environment;
    }

    dynamicEnvironment.token = token;
    dynamicEnvironment.environment = createEnvironment(token);

    return dynamicEnvironment.environment;
};
