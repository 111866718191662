import React from 'react';
import { useAppSelector } from '../../../../utilities/hooks';
import { FieldProject } from 'smx-components';

interface FieldProjectForAddItemProps {
    refListProject: React.RefObject<React.FC<any>>;
}

const FieldProjectForAddItem: React.FC<FieldProjectForAddItemProps> = ({ refListProject }) => {
    const globalFilterProject = useAppSelector((state) => state.app.globalFilterProject);
    const dataSettingFromServer = useAppSelector((state) => state.app.dataSettingFromServer);
    const user = useAppSelector((state) => state.account.user);

    const [textSettingGant] = dataSettingFromServer.rows.filter((set) => set.name === 'gant');

    const settingGant = JSON.parse(textSettingGant?.data || '{}');

    const disabledListProject = settingGant.gantEditScheduleItem
        ?.map((settingEditScheduleItem: { projectId: string; userId: string }) => {
            const isOtherAuthorEdit = user && settingEditScheduleItem.userId !== user?.id;

            if (isOtherAuthorEdit) {
                return settingEditScheduleItem.projectId;
            }
            return null;
        })
        .filter((id: string) => id);

    return (
        <FieldProject
            name={'projectId'}
            ref={refListProject as any}
            required
            localFilters={globalFilterProject.length ? { id: globalFilterProject } : undefined}
            disabledListProject={disabledListProject}
        />
    );
};

export default FieldProjectForAddItem;
