import React, { useEffect } from 'react';
import { Dropdown, MenuProps, Radio, RadioChangeEvent } from 'antd';
import { useAppDispatch } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';
import { SchedulePageViewType, ScheduleViewType } from '../../../../types';
import { CalendarViewType } from 'smx-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useRouting } from '../../../../routing/useRouting';

const ButtonScale = () => {
    const dispatch = useAppDispatch();
    const schedulePageView = useSelector<RootState, SchedulePageViewType | undefined>(
        (state) => state.schedule.schedulePageView,
    );

    const { navigate } = useRouting();

    const onChangeScale = ({ target: { value } }: RadioChangeEvent) => {
        dispatch(setterSchedule({ schedulePageView: value }));
    };

    useEffect(() => {
        let view: ScheduleViewType = 'calendar';
        let activeCalendarPage: CalendarViewType = 'schedule';

        if (schedulePageView === 'timeline') {
            view = 'gant';
        }

        if (schedulePageView === 'schedule') {
            activeCalendarPage = 'schedule';
        }

        if (schedulePageView === 'board') {
            view = 'taskBoard';
        }

        if (['listTask', 'listItem', 'list', 'table'].includes(schedulePageView || '')) {
            activeCalendarPage = 'table';
        }

        dispatch(setterSchedule({ activeCalendarPage, view }));

        switch (schedulePageView) {
            case 'list':
                navigate('/schedule/table');
                break;
            case 'listItem':
                navigate('/schedule/table/items');
                break;
            case 'listTask':
                navigate('/schedule/table/tasks');
                break;
            case 'timeline':
                navigate('/schedule/timeline');
                break;
            case 'board':
                navigate('/schedule/task-board');
                break;
            case 'schedule':
                navigate('/schedule/calendar');
                break;
        }
    }, [schedulePageView]);

    const clickToSwitchToScheduleItem = () => {
        dispatch(setterSchedule({ schedulePageView: 'listItem' }));
    };

    const clickToSwitchToTask = () => {
        dispatch(setterSchedule({ schedulePageView: 'listTask' }));
    };

    const items: MenuProps['items'] = [
        {
            key: '2',
            label: 'Schedule Item',
            onClick: clickToSwitchToScheduleItem,
        },
        {
            key: '1',
            label: 'Task',
            onClick: clickToSwitchToTask,
        },
    ];

    const getValue = () => {
        if (!schedulePageView) {
            return 'timeline';
        }

        return ['listTask', 'listItem'].includes(schedulePageView) ? 'list' : schedulePageView;
    };

    return (
        <Radio.Group value={getValue()} optionType="button">
            <Dropdown menu={{ items }} placement="bottomLeft">
                <Radio.Button value={'list'} onClick={clickToSwitchToScheduleItem}>
                    List
                </Radio.Button>
            </Dropdown>
            <Radio.Button value={'timeline'} onChange={onChangeScale}>
                Timeline
            </Radio.Button>
            <Radio.Button value={'schedule'} onChange={onChangeScale}>
                Schedule
            </Radio.Button>
            <Radio.Button value={'board'} onChange={onChangeScale}>
                Board
            </Radio.Button>
        </Radio.Group>
    );
};

export default ButtonScale;
