import { createSlice } from '@reduxjs/toolkit';
import { IConfirmationModalProps } from 'modals/ConfirmationModal';
import { ICategoriesTreeModalProps } from 'modals/CategoriesTreeModal';
import { IRenameModalProps } from 'modals/RenameModal';
import { IDocumentVersionsModalProps } from 'modals/DocumentVersionsModal';
import { IGuestTeamModalProps } from 'modals/GuestTeamModal';
import { ITenantCreateModalProps } from 'modals/TenantCreateModal';
import { IResolveUploadModalProps } from 'modals/ResolveUploadModal';
import { IHistoryModalProps } from 'modals/HistoryModal';
import { ICategoryCreateModalProps } from 'modals/CategoryCreateModal';
import { IDocumentMarkupModalProps } from 'modals/DocumentMarkupModal';

export type ModalNamesType =
    'confirmationModal' |
    'categoriesTreeModal' |
    'categoryCreateModal' |
    'documentMarkupModal' |
    'documentVersionsModal' |
    'guestTeamModal' |
    'historyModal' |
    'renameModal' |
    'resolveUploadModal' |
    'tenantCreateModal'
;

export type ModalPropsType =
    IConfirmationModalProps |
    ICategoriesTreeModalProps |
    ICategoryCreateModalProps |
    IDocumentMarkupModalProps |
    IDocumentVersionsModalProps |
    IGuestTeamModalProps |
    IHistoryModalProps |
    IRenameModalProps |
    IResolveUploadModalProps |
    ITenantCreateModalProps
;

type IModalsState = {
    [K in ModalNamesType]: {
        [id: string]: K extends 'confirmationModal' ? IConfirmationModalProps :
            K extends 'categoriesTreeModal' ? ICategoriesTreeModalProps :
                K extends 'categoryCreateModal' ? ICategoryCreateModalProps :
                    K extends 'documentMarkupModal' ? IDocumentMarkupModalProps :
                        K extends 'documentVersionsModal' ? IDocumentVersionsModalProps :
                            K extends 'guestTeamModal' ? IGuestTeamModalProps :
                                K extends 'historyModal' ? IHistoryModalProps :
                                    K extends 'renameModal' ? IRenameModalProps :
                                        K extends 'resolveUploadModal' ? IResolveUploadModalProps :
                                            K extends 'tenantCreateModal' ? ITenantCreateModalProps :
                                            null,
    }
}

const initialState: IModalsState = {
    confirmationModal: {},
    categoriesTreeModal: {},
    categoryCreateModal: {},
    documentMarkupModal: {},
    documentVersionsModal: {},
    guestTeamModal: {},
    historyModal: {},
    renameModal: {},
    resolveUploadModal: {},
    tenantCreateModal: {},
};

type ModalType = {
    id: string;
    modal: ModalNamesType;
    props?: ModalPropsType;
};

const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        openModal: (state: IModalsState, action: { payload: ModalType }) => {
            const modal = action.payload.modal;
            const props = action.payload.props;
            const id = action.payload.id;
            if (!props) return;

            state[modal][id] = {
                ...props,
            };
        },
        closeModal: (state: IModalsState, action: { payload: ModalType }) => {
            const modal = action.payload.modal;
            const id = action.payload.id;
            delete state[modal][id];
        },
    },
});

export const { openModal, closeModal } = modalsSlice.actions;
export default modalsSlice.reducer;
