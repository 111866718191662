import { Gant } from 'smx-components';
import { useAppDispatch, useAppSelector } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';

const WrapperGantTable = () => {
    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.account.user);
    const isFullScreen = useAppSelector((state) => state.schedule.isFullScreen);
    const globalFilterProject = useAppSelector((state) => state.app.globalFilterProject);

    const onFinishLoadData = () => {
        dispatch(setterSchedule({ isGantLoaded: true }));
    };

    return user ? (
        <Gant
            paddingBottom={!isFullScreen ? 20 : 0}
            mode={'table'}
            authorId={user?.id || ''}
            filterProject={globalFilterProject}
            onFinishLoadData={onFinishLoadData}
        />
    ) : null;
};

export default WrapperGantTable;
