import { useAppSelector } from 'utilities/hooks';
import { TenantsState } from 'slices/tenantsSlice';

const TenantLabelBar = () => {
    const { currentTenant } = useAppSelector<TenantsState>((state) => state.tenants);

    return <div>{currentTenant === null ? 'SiteMax System' : currentTenant.title}</div>;
};

export default TenantLabelBar;
