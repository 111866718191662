import React, { useEffect, useState, useMemo, useImperativeHandle, forwardRef } from 'react';
import { Select } from 'antd';
import { debounce } from '@mui/material/utils';
import { User } from 'interfaces/users';
import { Invitation } from 'interfaces/invitations';

interface UserAutocompleteProps {
    onDebounce: (search: string, callback: (values: (User & Invitation)[]) => void) => void;
    onChange: (value: (User & Invitation)[]) => void;
    label?: string;
    loading?: boolean;
    style?: React.CSSProperties;
}

export interface UserAutocompleteRef {
    refetch: (search?: string) => void;
    clear: () => void;
}

type OptionType = {
    label: string;
    value: string;
    item: User & Invitation;
}

const UserAutocomplete = forwardRef<UserAutocompleteRef, UserAutocompleteProps>(({
    onDebounce,
    onChange,
    label = 'Select users',
    loading = false,
    style = { width: '100%' },
}, ref) => {
    const [options, setOptions] = useState<OptionType[]>([]);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [searchText, setSearchText] = useState<string>('');

    const debounceInputChange = useMemo(
        () =>
            debounce((newValue: string) => {
                onDebounce(newValue, (values: (User & Invitation)[]) => {
                    const mappedValues: OptionType[] = values.map((value) => ({
                        label: value.name || value.email || value.phone,
                        value: value.id,
                        item: value,
                    }));
                    setOptions(mappedValues);
                });
            }, 500),
        [onDebounce]
    );

    const refetch = () => {
        debounceInputChange.clear();
        debounceInputChange(searchText);
    };

    const clear = () => {
        setSelectedValues([]);
        setOptions([]);
        onChange([]);
    };

    useImperativeHandle(ref, () => ({
        refetch,
        clear
    }));

    useEffect(() => {
        refetch();
    }, []);

    const handleChange = (newValue: string[]) => {
        if (!newValue.length) return;
        setSelectedValues(newValue);
        const fullValues = newValue.map((value) =>
            (options.find((option) => option.value === value) as OptionType).item
        );
        onChange?.(fullValues);
    };

    return (
        <Select
            mode="multiple"
            showSearch
            filterOption={false}
            value={selectedValues}
            options={options}
            placeholder={label}
            onChange={handleChange}
            onSearch={(search: string) => {
                setSearchText(search);
                debounceInputChange(search);
            }}
            searchValue={searchText}
            notFoundContent={loading ? 'Loading...' : 'No users found'}
            loading={loading}
            style={style}
            allowClear
            popupClassName="ant-picker-dropdown"
        />
    );
});

export default UserAutocomplete;