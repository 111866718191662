import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ModalCallbacksType, ModalContext } from 'providers/modalProvider';
import { closeModal, openModal } from 'slices/modalsSlice';
import type { ModalNamesType, ModalPropsType } from 'slices/modalsSlice';

export function useOpenModal(id: string) {
    const dispatch = useDispatch();
    const { registerModalCallbacks } = useContext(ModalContext);
    return (
        modal: ModalNamesType,
        props?: ModalPropsType,
        callbacks?: ModalCallbacksType
    ) => {
        // Dispatch serializable props to Redux
        dispatch(openModal({ modal, id, props: props || {} }));
        // Register non-serializable callbacks in your context
        registerModalCallbacks({ modal, id, callbacks: callbacks || {} });
    };
}

export function useCloseModal(id: string) {
    const dispatch = useDispatch();
    const { unregisterModalCallbacks } = useContext(ModalContext);

    return (modal: ModalNamesType) => {
        dispatch(closeModal({ modal, id }));
        unregisterModalCallbacks({ modal, id });
    };
}

export function useModal() {
    const id = uuidv4();
    return {
        openModal: useOpenModal(id),
        closeModal: useCloseModal(id),
    };
}
