import { FolderOutlined } from '@ant-design/icons';
import { Checkbox, Flex, List, Modal, Tag } from 'antd';
import CustomBreadCrumbs from 'components/CustomBreadCrumbs';
import {
    Category
} from 'interfaces/documents';
import {
    useCallback,
    useEffect,
    useState
} from 'react';
import {
    fetchCategories
} from 'slices/categoriesActions';
import { closeModal } from 'slices/modalsSlice';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

export const modalName = 'categoriesTreeModal';

export interface ICategoriesTreeModalProps {
    title?: string;
    submitLabel?: string;
    cancelLabel?: string;
};

export interface ICategoriesTreeModalCallbacks {
    onSubmit?: (value: Category | null) => void;
    onCancel?: () => void;
    onClose?: () => void;
}

const CategoriesTreeModal = ({ id, zIndex, props, callbacks }: { id: string, zIndex: number, props: ICategoriesTreeModalProps, callbacks: ICategoriesTreeModalCallbacks }) => {
    const dispatch = useAppDispatch();
    const {title, submitLabel, cancelLabel} = props;
    const { onSubmit, onCancel } = callbacks;
    const rootTitle = 'Documents';
    const [isOpen, setIsOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState< Category | null >(null);
    const { list, excluded, breadcrumbs, loading } = useAppSelector((state) => state.categories);

    useEffect(() => {
        if (props && !isOpen) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [props]);

    const _fetchCategories = useCallback((categoryId: any) => {
        return fetchCategories(categoryId);
    }, [isOpen]);

    const close = () => {
        dispatch(closeModal({ modal: modalName, id }));
    };

    useEffect(() => {
        if (isOpen) {
            setSelectedCategory(null);
            dispatch(_fetchCategories(null));
        }
    }, [isOpen]);

    useEffect(() => {
        /**
         * Nothing to exclude
         */
        if (excluded.length == 0) {
            setIsDisabled(false);
            return;
        }

        /**
         * Root category (null) can't be excluded
         */
        if (!selectedCategory) {
            setIsDisabled(false);
            return;
        }

        /**
         * Find out if selected category excluded
         */
        const isExcluded = excluded.find((id: number) => {
            return id == selectedCategory?.id;
        });
        if (isExcluded) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [excluded, selectedCategory]);


    const handleSubmit = () => {
        onSubmit?.(selectedCategory);
        close();
    };

    const handleCancel = () => {
        onCancel?.();
        close();
    };
    const handleCategoryClick = (category: Category) => {
        if (excluded.includes(category.id)) return;

        setSelectedCategory(category);
        if (category.has_children) {
            dispatch(_fetchCategories(category.uuid));
        }
    };

    const handleCategoryToggle = (category: Category) => {
        if (excluded.includes(category.id)) return;

        if (selectedCategory?.id == category.id) {
            setSelectedCategory(null);
        } else {
            setSelectedCategory(category);
        }
    };

    return <>
        <Modal
            zIndex={zIndex}
            title={
                <>
                    {title}{" "}
                    <Tag icon={<FolderOutlined />}>
                    {selectedCategory ? selectedCategory.name : rootTitle}
                    </Tag>
                </>
            }
            open={isOpen}
            onCancel={handleCancel}
            onOk={handleSubmit}
            okText={submitLabel}
            cancelText={cancelLabel}
            okButtonProps={{ disabled: isDisabled }}
        >
            <Flex style={{ marginTop: 20 }}>
                <CustomBreadCrumbs
                    title={rootTitle}
                    variant={'h6'}
                    loading={loading}
                    breadcrumbs={breadcrumbs}
                    onRootClick={() => dispatch(_fetchCategories(null))}
                    onClick={(breadcrumb: any) => dispatch(_fetchCategories(breadcrumb.uuid))}
                />
            </Flex>
            <Flex style={{ height: '220px' }}>
                <List
                    style={{ width: '100%', overflowY: 'scroll' }}
                    loading={loading}
                    dataSource={list}
                    renderItem={(category: Category) => {
                        const isExcluded = excluded.includes(category.id);
                        return (
                            <List.Item
                                key={category.id}
                                actions={
                                !isExcluded
                                    ? [
                                        <Checkbox
                                            checked={selectedCategory?.id === category.id}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={() => handleCategoryToggle(category)}
                                        />,
                                    ]
                                    : []
                                }
                                style={{
                                    backgroundColor:
                                        selectedCategory?.id === category.id ? "#e6f7ff" : "transparent",
                                    cursor: isExcluded ? "not-allowed" : "pointer",
                                    width: '100%',
                                }}
                                onClick={() => !isExcluded && handleCategoryClick(category)}
                            >
                                <List.Item.Meta
                                    avatar={<FolderOutlined />}
                                    title={category.name}
                                />
                            </List.Item>
                        );
                    }}
                />
            </Flex>
        </Modal>
    </>;
};

export default CategoriesTreeModal;