import { Calendar } from 'smx-components';
import { useAppDispatch, useAppSelector } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';

const WrapperCalendarTable = () => {
    const dispatch = useAppDispatch();

    const isFullScreen = useAppSelector((state) => state.schedule.isFullScreen);
    const globalFilterProject = useAppSelector((state) => state.app.globalFilterProject);
    const user = useAppSelector((state) => state.account.user);

    const onFinishLoadData = () => {
        dispatch(setterSchedule({ isGantLoaded: true }));
    };

    return (
        <Calendar
            paddingBottom={!isFullScreen ? 40 : 0}
            activePage={'table'}
            filterProject={globalFilterProject}
            authorId={user?.id || ''}
            onFinishLoadData={onFinishLoadData}
        />
    );
};

export default WrapperCalendarTable;
