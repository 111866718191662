import { Key, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Category, Document } from 'interfaces/documents';
import DocumentMarkupModal from 'modals/DocumentMarkupModal';
import { BaseModalRef } from 'modals/BaseModal';
import { setDocument } from 'slices/documentSlice';
import { useAppDispatch } from 'utilities/hooks';
import DocumentsToolbar from './DocumentsToolbar';
import ProgressCard, { ProgressCardRef } from 'components/ProgressCard';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import UploadMenu, { UploadMenuRef } from 'components/UploadMenu';
import { useDocumentsPage } from 'hooks/useDocumentsPage';
import BaseTableDocuments from './BaseTableDocuments';
import { Col, Row, Tree, TreeDataNode } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { DirectoryTreeProps } from 'antd/es/tree';
import { useModal } from 'hooks/useModal';

const { DirectoryTree } = Tree;

const DocumentsTable = ({
    shared = false,
    deleted = false,
    hasFileTree = false,
}: {
    shared?: boolean,
    deleted?: boolean,
    hasFileTree?: boolean,
}) => {
    // TODO: because of the useDocumentsPage hook, the DocumentsTable component is re-rendered
    console.log('DocumentsTable');
    const {
        categoryId,
        list,
        tree,
        fetchData,
        navigateTo,
    } = useDocumentsPage({ isShared: shared, isDeleted: deleted });

    useEffect(() => {
        fetchData();
    }, [categoryId]);

    const dispatch = useAppDispatch();
    const { openModal: openDocumentMarkupModal } = useModal();

    const progressCard = useRef<ProgressCardRef>(null);
    const uploadMenuRef = useRef<UploadMenuRef>(null);

    const [selectedItems, setSelectedItems] = useState<(Category | Document)[]>([]);

    const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        uploadMenuRef.current?.drop(acceptedFiles as unknown as FileList);
    }, []);

    const { getRootProps } = useDropzone({ onDrop });

    const handleDocumentOpen = (document: Document) => {
        dispatch(setDocument(document));
        openDocumentMarkupModal('documentMarkupModal');
    };

    const tableMemo = useMemo(() => {
        return <BaseTableDocuments
            setSelectedItems={setSelectedItems}
            shared={shared}
            deleted={deleted}
        />
    }, [list]);

    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

    const findPathToNode = (
        nodes: TreeDataNode[] | undefined,
        targetId: number,
        currentPath: Key[] = []
    ): Key[] | null => {
        if (!nodes) return null;

        for (const node of nodes) {
            const newPath = [...currentPath, node.key];

            if (node.key === targetId) {
                return newPath;
            }

            if (node.children) {
                const pathInChildren = findPathToNode(node.children, targetId, newPath);
                if (pathInChildren) {
                    return pathInChildren;
                }
            }
        }

        return null;
    };

    useEffect(() => {
        if (tree.length > 0) {
            const path = findPathToNode(tree, Number(categoryId));

            if (path) {
                setExpandedKeys(path);
            }
        }
    }, [tree]);

    const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {
        setExpandedKeys(keys);
    };

    const onSelect: DirectoryTreeProps['onSelect'] = (selectedKeys, info) => {
        navigateTo(Number(selectedKeys[0]));
    };

    return (
        <div {...getRootProps({
            onClick: (event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()
        })}>
            <DocumentsToolbar categoryId={categoryId} fetchData={fetchData} selected={selectedItems} shared={shared} deleted={deleted} />

            {hasFileTree ? <Row>
                <Col span={4}>
                    {tree && <DirectoryTree
                        showLine
                        switcherIcon={<DownOutlined />}
                        expandedKeys={expandedKeys}
                        onExpand={onExpand}
                        onSelect={onSelect}
                        treeData={tree}
                    />}
                </Col>
                <Col span={20}>
                    {tableMemo}
                </Col>
            </Row> : tableMemo}

            <ProgressCard
                ref={progressCard}
                title='Uploading files...'
                onOpenClick={(item: { data: Document }) => item.data && handleDocumentOpen(item.data)}
            ></ProgressCard>
            <UploadMenu
                uploadref={uploadMenuRef}
                anchorEl={null}
                open={false}
                onClose={() => { }}
                onComplete={() => {
                    fetchData();
                }}
            ></UploadMenu>
        </div>
    );
}

export default DocumentsTable;
